/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import type { Tag } from '@sharefile/tags-client';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import type { ButtonProps } from 'antd';
import type { LocaleString } from '../utils';

const prefix = 'urn';
const owner = 'templates-pilet';
const modalNamespace = 'sfmodal';
const uiBlockNamespace = 'block';
const drawerNamespace = 'sfdrawer';
const extensionNamespace = 'sfextslot';
const resourceType = 'template';

enum TemplateListTabKeys {
	MyTemplates = 'my-templates',
	SharedTemplates = 'shared-with-me',
	InstalledTemplates = 'installed-templates',
	RunHistory = 'run-history',
}

export const templatesPiletExtensionSlots = {
	editProperties:
		`${prefix}:${extensionNamespace}:${resourceType}:package-editor-title-slot` as const,
};

export const templatePiletBlocks = {
	createFromSpecificTemplateButtonBlock:
		`${prefix}:${uiBlockNamespace}:${owner}:create-from-specific-template-button` as const,
	createFromTemplateWithTagButtonBlock:
		`${prefix}:${uiBlockNamespace}:${owner}:create-from-template-with-tag-button` as const,
};

export const templatesPiletOverlays = {
	selectTemplate: `${prefix}:${modalNamespace}:${owner}:select-template` as const,
	useTemplate: `${prefix}:${modalNamespace}:${owner}:use-template` as const,
	useTemplateDrawer: `${prefix}:${drawerNamespace}:${owner}:use-template` as const,
	saveAsTemplate: `${prefix}:${modalNamespace}:${owner}:save-as-template` as const,
};

export const internalTemplatesPiletOverlays = {
	templateDetails: `${prefix}:${modalNamespace}:${owner}:template-details` as const,
	templateProperties:
		`${prefix}:${drawerNamespace}:${owner}:template-properties` as const,
	templateDuplicate: `${prefix}:${drawerNamespace}:${owner}:template-duplicate` as const,
};

export type EditPropertiesExtensionParams = {
	rid: string;
};

export type BaseCreateFromTemplateBlockParams = {
	resourceType: string;
	verticalContextKey?: string;
	buttonText: LocaleString;
	cancelButtonText?: LocaleString;
	titleText?: LocaleString;
	type?: ButtonProps['type'];
	size?: ButtonProps['size'];
	onCompleteNavigationPath?: string;
};

export type CreateFromSpecificTemplateButtonBlockParams =
	BaseCreateFromTemplateBlockParams & {
		templateRID: string;
		showTemplateMetadata?: boolean;
		context?: BaseTemplateContext;
	};

export type CreateFromTemplateWithTagButtonBlockParams =
	BaseCreateFromTemplateBlockParams & {
		tags: Tag[];
		templateRID?: string;
		containerRID?: string;
		defaultTabKey?: TemplateListTabKeys;
		pickerTitleText?: LocaleString;
		disableTuning?: boolean;
	};

export type Context = { [key: string]: any };

export type BaseTemplateContext = Context;

export type BaseTemplateOverlayParams = {
	resourceType: string;
	containerRID?: string;
	templateRID?: string;
	tags?: Tag[];
	context?: SelectTemplateContext;
};

export type UseTemplateOverlayParams = BaseTemplateOverlayParams & {
	onCompleteNavigationPath?: string;
	buttonText?: LocaleString;
	cancelButtonText?: LocaleString;
};

export type SelectTemplateContext = Context;

export type SelectTemplateOverlayParams = BaseTemplateOverlayParams & {
	defaultTabKey?: TemplateListTabKeys;
	showSolutionTemplatesOnly?: boolean;
	includeHidden?: boolean;
	setData?: (templateRID: string) => void;
};

export type UseTemplateDrawerOverlayParams = {
	params: UseTemplateOverlayParams;
	/** @deprecated */
	context?: BaseTemplateContext;
};

export type SaveAsTemplateOverlayParams = {
	resourceType: string;
	context: {
		RID: string;
		metadata?: {
			name?: string;
			description?: string;
		};
	};
};

export type SaveAsTemplateOverlayDataMap = {
	templateRID: string;
};

export type TemplateDetailsOverlayParams = {
	templateRID: string;
};

export type TemplatePropertiesOverlayParams = {
	templateRID: string;
};

export type TemplateDuplicateOverlayParams = {
	template: {
		RID: string;
		name: string;
		description: string;
	};
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[templatesPiletExtensionSlots.editProperties]: EditPropertiesExtensionParams;
	}

	interface PiralCustomOverlayOptionsMap {
		[templatesPiletOverlays.selectTemplate]: SelectTemplateOverlayParams;
		[templatesPiletOverlays.useTemplate]: UseTemplateOverlayParams;
		[templatesPiletOverlays.useTemplateDrawer]: UseTemplateDrawerOverlayParams;
		[templatesPiletOverlays.saveAsTemplate]: SaveAsTemplateOverlayParams;
		[internalTemplatesPiletOverlays.templateDetails]: TemplateDetailsOverlayParams;
		[internalTemplatesPiletOverlays.templateProperties]: TemplatePropertiesOverlayParams;
		[internalTemplatesPiletOverlays.templateDuplicate]: TemplateDuplicateOverlayParams;
		[templatePiletBlocks.createFromSpecificTemplateButtonBlock]: CreateFromSpecificTemplateButtonBlockParams;
		[templatePiletBlocks.createFromTemplateWithTagButtonBlock]: CreateFromTemplateWithTagButtonBlockParams;
	}

	interface PiralCustomOverlayDataMap {
		[templatesPiletOverlays.saveAsTemplate]: SaveAsTemplateOverlayDataMap;
	}
}
