import { capabilities as projectsCapabilities } from '@sharefiledev/projects-pilet';
import { Capabilities } from '@sharefiledev/sfplugin-capability-contracts';

const allCapabilities = { ...projectsCapabilities };

export function getEntityCapability(
	resourceEntityType: string
): Capabilities | undefined {
	return allCapabilities[resourceEntityType];
}
