import { lazy, Suspense } from 'react';
import { Skeleton } from 'antd';

const LazyEditPropertiesExtension = lazy(() => import('./EditProperties'));

export const AsyncLazyEditPropertiesExtension = props => (
	<Suspense fallback={<Skeleton />}>
		<LazyEditPropertiesExtension {...props} />
	</Suspense>
);
