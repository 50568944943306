import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logError } from '../../../logger';
import {
	ConstructTemplateParams,
	CopyTemplateParams,
	CopyTemplateResponse,
	CreateRunParams,
	CreateTemplateRunResponse,
	DeleteTemplateParams,
	GetRunLogsParams,
	GetRunLogsResponse,
	GetRunParams,
	GetTemplateParams,
	GetTemplateRunResponse,
	ListRunsParams,
	ListTemplatesParams,
	TemplateInfo,
	UpdateTemplateParams,
	UpdateTemplateResponse,
} from './types';

export interface TemplatesSdk {
	listTemplates(
		params?: Omit<ListTemplatesParams, 'filter' | 'searchTerm' | 'enabled'>
	): Promise<TemplateInfo[]>;
	getTemplate(params: GetTemplateParams): Promise<TemplateInfo>;
	deleteTemplate(params: DeleteTemplateParams): Promise<void>;
	createRun(params: CreateRunParams): Promise<CreateTemplateRunResponse>;
	getRun(params: GetRunParams): Promise<GetTemplateRunResponse>;
	constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo>;
	getRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse>;
	listRuns(params: ListRunsParams): Promise<GetTemplateRunResponse[]>;
	copyTemplate(params: CopyTemplateParams): Promise<CopyTemplateResponse>;
	updateTemplate(params: UpdateTemplateParams): Promise<UpdateTemplateResponse>;
}

export interface TemplatesSdkConfiguration {
	baseUrl: string;
}

export function initializeSdk(configuration: TemplatesSdkConfiguration): TemplatesSdk {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: configuration.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const templatesApi = axios.create(sharedConfig);

	return {
		listTemplates(
			params?: Omit<ListTemplatesParams, 'filter' | 'searchTerm' | 'enabled'>
		): Promise<TemplateInfo[]> {
			return apiProxy(async () => {
				try {
					return await templatesApi.post(
						'/v2/templates/list',
						{
							tags: params?.tags,
						},
						{
							params: {
								containerRID: params?.parentRID,
								rootType: params?.rootType,
								...(params?.includeHidden && { includeHidden: true }),
							},
						}
					);
				} catch (error) {
					// Try old endpoint if new one fails (such as not available in environment)
					return await templatesApi.get('/v2/templates', {
						params: {
							containerRID: params?.parentRID,
							rootType: params?.rootType,
						},
					});
				}
			}, 'listTemplates');
		},
		getTemplate(params: GetTemplateParams): Promise<TemplateInfo> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/templates/${encodeURIComponent(params.id)}`);
			}, 'getTemplate');
		},
		deleteTemplate(params: DeleteTemplateParams): Promise<void> {
			return apiProxy(async () => {
				return await templatesApi.delete(
					`/v2/templates/${encodeURIComponent(params.id)}`
				);
			}, 'deleteTemplate');
		},
		createRun(params: CreateRunParams): Promise<CreateTemplateRunResponse> {
			return apiProxy(async () => {
				return await templatesApi.post(
					`/v2/templates/${encodeURIComponent(params.templateId)}/run`,
					{
						targetRID: params.parentRID,
						args: params.args,
					}
				);
			}, 'createRun');
		},
		getRun(params: GetRunParams): Promise<GetTemplateRunResponse> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/templates/runs/${params.id}`);
			}, 'getRun');
		},
		constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo> {
			return apiProxy(async () => {
				return await templatesApi.post('/v2/templates/construct', {
					RID: params.RID,
					name: params.packageName,
					description: params.packageDescription,
				});
			}, 'constructTemplate');
		},
		getRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/executions/${params.executionId}/logs`);
			}, 'getRunLogs');
		},
		listRuns(params: ListRunsParams): Promise<GetTemplateRunResponse[]> {
			return apiProxy(async () => {
				return await templatesApi.get('/v2/templates/runs', {
					params: {
						templateRID: params.templateRID,
					},
				});
			}, 'listRuns');
		},
		copyTemplate(params: CopyTemplateParams): Promise<CopyTemplateResponse> {
			return apiProxy(async () => {
				return await templatesApi.post(
					`/v2/templates/${encodeURIComponent(params.RID)}/copy`,
					{
						...(params.name ? { name: params.name } : {}),
						...(params.description ? { description: params.description } : {}),
						...(params.overview ? { overview: params.overview } : {}),
					}
				);
			}, 'copyTemplate');
		},
		updateTemplate(params: UpdateTemplateParams): Promise<UpdateTemplateResponse> {
			return apiProxy(async () => {
				return await templatesApi.put(`/v2/templates/${encodeURIComponent(params.RID)}`, {
					name: params.name,
					description: params.description,
				});
			}, 'updateTemplate');
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Templates API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
