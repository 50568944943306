import AntIcon from '@ant-design/icons';

const DataTableIconSvg = () => (
	<svg
		width="108"
		height="98"
		viewBox="0 0 108 98"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<rect
				x="8"
				y="16.0371"
				width="92"
				height="70"
				rx="3"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="1"
				y="1.03712"
				width="106"
				height="80"
				rx="3"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M1 4.03712C1 2.38027 2.34315 1.03712 4 1.03712H104C105.657 1.03712 107 2.38027 107 4.03712V16.0371H1V4.03712Z"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M1 4.03711C1 2.38026 2.34315 1.03711 4 1.03711H104C105.657 1.03711 107 2.38026 107 4.03711V16.0371H1V4.03711Z"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M1 4.03711C1 2.38026 2.34315 1.03711 4 1.03711H104C105.657 1.03711 107 2.38026 107 4.03711V16.0371H1V4.03711Z"
				fill="#0C0D0D"
			/>
			<circle cx="9" cy="9.03711" r="2" fill="white" />
			<circle cx="17" cy="9.03711" r="2" fill="white" />
			<circle cx="25" cy="9.03711" r="2" fill="white" />
			<path
				d="M27 16.0371V81.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M43 16.0371V81.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M60 16.0371V81.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M76 16.0371V81.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M93 16.0371V81.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M107 29.0371L0.999994 29.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M107 42.0371L0.999994 42.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M107 55.0371L0.999994 55.0371"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M107 68.0371H0.999994"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<rect x="4" y="21.0371" width="20" height="3" fill="#0C0D0D" />
			<rect x="30" y="21.0371" width="10" height="3" fill="#0C0D0D" />
			<rect x="96" y="21.0371" width="8" height="3" fill="#0C0D0D" />
			<rect x="46" y="21.0371" width="11" height="3" fill="#0C0D0D" />
			<rect x="63" y="21.0371" width="10" height="3" fill="#0C0D0D" />
			<rect x="79" y="21.0371" width="11" height="3" fill="#0C0D0D" />
			<circle cx="35" cy="48.0371" r="2" fill="#0C0D0D" />
			<circle cx="85" cy="62.0371" r="2" fill="#0C0D0D" />
			<circle cx="14" cy="35.0371" r="2" fill="#0C0D0D" />
			<circle cx="51" cy="75.0371" r="2" fill="#0C0D0D" />
		</g>
	</svg>
);

export const DataTableIcon = props => <AntIcon {...props} component={DataTableIconSvg} />;
