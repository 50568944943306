import { UserPreferences } from '@citrite/sf-api';
import moment from 'moment';
import { locale } from '../locales';
import { getLanguage } from './translate';

export type DateTypes = Date | moment.Moment | string | number;

export enum Time {
	NeverDateString = '9999-12-31T23:59:59.9999999Z',
	ShareFileEpochString = '2005-01-01T00:00:00.0000001Z',
	NoExpirationDateThresholdString = '2100-12-31T23:59:59.9999999Z',
}

export function formatDateTime(
	date: DateTypes,
	{ DateFormat, TimeFormat }: UserPreferences
) {
	const dateTimeFormat = `${DateFormat?.toUpperCase()} ${normalizeTimeFormat(
		TimeFormat
	)}`;
	return formatWithLocale(date, dateTimeFormat);
}

const localesWithAlternateTime: locale[] = ['ja'];

export function normalizeTimeFormat(format = 'h:mmt') {
	if (format === 'h:mmt' && localesWithAlternateTime.includes(getLanguage() as locale)) {
		return 'A h:mm';
	}
	return format.replace('t', ' A');
}

export function formatWithLocale(date: DateTypes, format: string) {
	return moment(date).locale(getLanguage()).format(format);
}

export const getPrettyDate = (created: DateTypes) =>
	formatDateTime(created, {
		DateFormat: 'MM/DD/YY',
		TimeFormat: 'h:mmt',
	}).split(' ')[0];

export function timeFromNow(dateTime: string) {
	return moment(dateTime).locale(getLanguage()).fromNow();
}
