import React from 'react';
import {
	OverlayDrawerComponentProps,
	OverlayModalComponentProps,
} from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { templatesPiletOverlays } from '../sdk';
import {
	internalTemplatesPiletOverlays,
	SaveAsTemplateOverlayParams,
	SelectTemplateOverlayParams,
	TemplateDetailsOverlayParams,
	TemplateDuplicateOverlayParams,
	TemplatePropertiesOverlayParams,
	UseTemplateOverlayParams,
} from '../sdk/extensionTypes';

export * from './getDefaultOverlayOptions';

const LazyUseTemplateOverlayContent = React.lazy(
	() => import('./UseTemplateOverlayContent')
);

export type UseTemplateOverlayProps = UseTemplateOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof templatesPiletOverlays.useTemplate>,
		'completeInteraction' | 'cancelInteraction'
	>;

export const UseTemplateOverlay: React.FC<UseTemplateOverlayProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyUseTemplateOverlayContent {...props} />
	</React.Suspense>
);

const LazySelectTemplateOverlayContent = React.lazy(
	() => import('./SelectTemplateOverlayContent')
);

export type SelectTemplateOverlayProps = SelectTemplateOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof templatesPiletOverlays.selectTemplate>,
		'completeInteraction' | 'cancelInteraction'
	>;

export const SelectTemplateOverlay: React.FC<SelectTemplateOverlayProps> = props => (
	<React.Suspense fallback={<>loading...</>}>
		<LazySelectTemplateOverlayContent {...props} />
	</React.Suspense>
);

const LazyTemplateDetailsOverlayContent = React.lazy(
	() => import('./TemplateDetailsOverlayContent')
);

export type TemplateDetailsOverlayProps = TemplateDetailsOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof internalTemplatesPiletOverlays.templateDetails>,
		'completeInteraction' | 'cancelInteraction'
	>;

export const TemplateDetailsOverlay: React.FC<TemplateDetailsOverlayProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyTemplateDetailsOverlayContent {...props} />
	</React.Suspense>
);

const LazySaveAsTemplateOverlayContent = React.lazy(
	() => import('./SaveAsTemplateOverlayContent')
);

export type SaveAsTemplateOverlayProps = SaveAsTemplateOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof templatesPiletOverlays.saveAsTemplate>,
		'completeInteraction' | 'cancelInteraction'
	>;

export const SaveAsTemplateOverlay: React.FC<SaveAsTemplateOverlayProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazySaveAsTemplateOverlayContent {...props} />
	</React.Suspense>
);

const LazyTemplatePropertiesOverlayContent = React.lazy(
	() => import('./TemplatePropertiesOverlayContent')
);

export type TemplatePropertiesOverlayProps = TemplatePropertiesOverlayParams &
	Pick<
		OverlayDrawerComponentProps<typeof internalTemplatesPiletOverlays.templateProperties>,
		'completeInteraction' | 'cancelInteraction'
	>;

export const TemplatePropertiesOverlay: React.FC<
	TemplatePropertiesOverlayProps
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyTemplatePropertiesOverlayContent {...props} />
	</React.Suspense>
);

const LazyTemplateDuplicateOverlayContent = React.lazy(
	() => import('./TemplateDuplicateOverlayContent')
);

export type TemplateDuplicateOverlayProps = TemplateDuplicateOverlayParams &
	Pick<
		OverlayDrawerComponentProps<typeof internalTemplatesPiletOverlays.templateDuplicate>,
		'completeInteraction' | 'cancelInteraction' | 'updateDrawerProps' | 'options'
	>;

export const TemplateDuplicateOverlay: React.FC<
	TemplateDuplicateOverlayProps
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyTemplateDuplicateOverlayContent {...props} />
	</React.Suspense>
);
