export enum EmptyStates {
	ERROR_LOADING_TEMPLATES,
	NO_CATEGORY_RESULTS,
	NO_SEARCH_RESULTS,
	MY_TEMPLATES_FTU,
	MY_TEMPLATES_EMPTY,
	SHARED_TEMPLATES_FTU,
	SHARED_TEMPLATES_EMPTY,
	INSTALLED_TEMPLATES_FTU,
	INSTALLED_TEMPLATES_EMPTY,
	ERROR_LOADING_RUNS,
}

export const errorStates = [EmptyStates.ERROR_LOADING_TEMPLATES];

export const noSearchOrFilterResults = [
	EmptyStates.NO_CATEGORY_RESULTS,
	EmptyStates.NO_SEARCH_RESULTS,
];

export const noTemplatesStates = [
	EmptyStates.MY_TEMPLATES_FTU,
	EmptyStates.MY_TEMPLATES_EMPTY,
	EmptyStates.SHARED_TEMPLATES_FTU,
	EmptyStates.SHARED_TEMPLATES_EMPTY,
	EmptyStates.INSTALLED_TEMPLATES_FTU,
	EmptyStates.INSTALLED_TEMPLATES_EMPTY,
];

type EmptyStateTokens = {
	title: string;
	subTitle?: string;
	actionLabel?: string;
	actionMessage?: string;
};

export type EmptyStateSet = Record<EmptyStates, EmptyStateTokens>;

export const tokens: EmptyStateSet = {
	[EmptyStates.ERROR_LOADING_TEMPLATES]: {
		title: 'messages.empty.error_loading_templates.title',
	},
	[EmptyStates.NO_CATEGORY_RESULTS]: {
		title: 'messages.empty.no_category_results.title',
	},
	[EmptyStates.NO_SEARCH_RESULTS]: {
		title: 'messages.empty.no_search_results.title',
		subTitle: 'messages.empty.no_search_results.subtitle',
		actionLabel: 'messages.empty.no_search_results.action_label',
	},
	[EmptyStates.MY_TEMPLATES_EMPTY]: {
		title: 'messages.empty.my_templates.title',
		subTitle: 'messages.empty.my_templates.subtitle',
	},
	[EmptyStates.MY_TEMPLATES_FTU]: {
		title: 'messages.empty.my_templates_ftu.title',
		subTitle: 'messages.empty.my_templates_ftu.subtitle',
		actionMessage: 'messages.empty.my_templates_ftu.action_message',
	},
	[EmptyStates.SHARED_TEMPLATES_EMPTY]: {
		title: 'messages.empty.shared_templates.title',
		subTitle: 'messages.empty.shared_templates.subtitle',
	},
	[EmptyStates.SHARED_TEMPLATES_FTU]: {
		title: 'messages.empty.shared_templates_ftu.title',
		subTitle: 'messages.empty.shared_templates_ftu.subtitle',
	},
	[EmptyStates.INSTALLED_TEMPLATES_EMPTY]: {
		title: 'messages.empty.installed_templates.title',
		subTitle: 'messages.empty.installed_templates.subtitle',
		actionLabel: 'messages.empty.installed_templates.action_label',
	},
	[EmptyStates.INSTALLED_TEMPLATES_FTU]: {
		title: 'messages.empty.installed_templates_ftu.title',
		subTitle: 'messages.empty.installed_templates_ftu.subtitle',
		actionLabel: 'messages.empty.installed_templates_ftu.action_label',
	},
	[EmptyStates.ERROR_LOADING_RUNS]: {
		title: 'There was an error loading runs. Please try again.',
	},
};
