import { useMemo } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Redirect } from 'react-router-dom';
import { checkRolesAndPreferences } from './evaluateProvisioning';
import { RolesAndProvisioningRequirements } from './types';

interface RenderConditionallyProps {
	piletApi: PiletApi;
	children: any;
	requirements: RolesAndProvisioningRequirements;
	redirectToDashboard?: boolean;
}

export const RenderConditionally: React.FC<RenderConditionallyProps> = props => {
	const isAllowed = useMemo(() => {
		return checkRolesAndPreferences(props.piletApi, props.requirements);
	}, [props.piletApi, props.requirements]);

	return isAllowed ? (
		<>{props.children}</>
	) : props.redirectToDashboard ? (
		<Redirect to="/dashboard"></Redirect>
	) : (
		<></>
	);
};

export default RenderConditionally;
