import AntIcon from '@ant-design/icons';

const RequestListIconSvg = () => (
	<svg
		width="112"
		height="112"
		viewBox="0 0 112 112"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<rect
				x="27"
				y="19"
				width="68"
				height="84"
				fill="white"
				stroke="#0C0D0D"
				stroke-width="2"
			/>
			<rect
				x="22"
				y="14"
				width="68"
				height="84"
				fill="white"
				stroke="#0C0D0D"
				stroke-width="2"
			/>
			<path d="M17 9H69L85 25V93H17V9Z" fill="white" stroke="#0C0D0D" stroke-width="2" />
			<path
				d="M38.2412 49.4213H58.2412C60.4503 49.4213 62.2412 51.2121 62.2412 53.4213V63.9329"
				stroke="black"
				stroke-width="2"
			/>
			<path
				d="M47.1715 58.3516L38.2412 49.4214L47.1715 40.4911"
				stroke="black"
				stroke-width="2"
			/>
			<path d="M69 14H17V9H69V14Z" fill="black" />
			<path d="M85 25H69V9" stroke="black" stroke-width="2" stroke-miterlimit="10" />
		</g>
	</svg>
);

export const RequestListIcon = props => (
	<AntIcon {...props} component={RequestListIconSvg} />
);
