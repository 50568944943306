import { PiletApi } from '@sharefiledev/sharefile-appshell';

export function getApiBaseUrl(app: PiletApi) {
	let apiOrigin = window.origin;

	try {
		if (typeof app.meta.config.apiOrigin === 'string') {
			apiOrigin = app.meta.config.apiOrigin;
		}
	} catch (_error) {
		// Do nothing (condition under try block is only meaningful for test/dev environment)
	}

	return `${apiOrigin}/io/packaging`;
}
