import * as React from 'react';
import { Typography } from 'antd';
import { t } from './translate';

export const createListFromString = (
	translationId: string,
	size: number
): React.ReactNode => {
	// split doesn't work without the stringification
	const splitMessage = JSON.stringify(t(translationId, { returnObjects: true }))
		.replace('["', '') // leading array characters
		.replace('"]', '') // trailing array characters
		.split('","');

	return (
		<ul>
			{splitMessage.map((str, idx) => (
				<li key={idx}>
					<Typography.Text style={{ fontSize: size }}>{str}</Typography.Text>
				</li>
			))}
		</ul>
	);
};

export const createMessageComponentWithEmphasis = (
	translationId: string,
	attrName: string,
	attrValue: string
): React.ReactNode => {
	const TAG = '***TAG***';
	const splitMessage = t(translationId, { [attrName]: TAG }).split(TAG);
	return (
		<Typography>
			{splitMessage[0]}
			<strong>
				{attrValue.length > 249 ? attrValue.substring(0, 249) + '...' : attrValue}
			</strong>
			{splitMessage[1]}
		</Typography>
	) as React.ReactNode;
};
