import { getFeatureFlagValue } from '@citrite/feature-flags';
import { FeatureFlag } from '../../featureFlagDefinitions';

export function checkFeatureFlags(flags?: FeatureFlag[]) {
	if (!!!flags) {
		return true;
	}
	return flags.reduce((acc, flag) => {
		return acc && getFeatureFlagValue(flag);
	}, true);
}
