import AntIcon from '@ant-design/icons';

const ProjectIconSvg = () => (
	<svg
		width="86"
		height="79"
		viewBox="0 0 86 79"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<path
				d="M5.00684 23.5H81.0068V40.5H5.00684V23.5Z"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M9.00684 1.5H61.0068L77.0068 17.5V77.5H9.00684V1.5Z"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M17.0068 38.5H64.0068"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M17.0068 43.5H48.0068"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path d="M61.0068 6.5H9.00684V1.5H61.0068V6.5Z" fill="black" />
			<path
				d="M77.0068 17.5H61.0068V1.5"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M20.0068 15.5H37.0068"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M20.0068 20.5H28.0068"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M2.00684 28.5L8.00684 77.4247H77.9931L83.9931 36.3082H42.3493L39.0959 28.5H2.00684Z"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M38.6918 28.4999H2.00684V32.4041H39.9931L38.6918 28.4999Z"
				fill="#0C0D0D"
			/>
			<path
				d="M7.00684 72.5L79.0068 72.5"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</g>
	</svg>
);

export const ProjectIcon = props => <AntIcon {...props} component={ProjectIconSvg} />;
