import { parseRID } from '@sharefile/rid-utils';
import { getEntityCapability } from './capabilities';

export const getResourceNavigationDetails = async (RID: string) => {
	const resourceType = parseRID(RID)?.leaf.name;
	const capabilities = getEntityCapability(resourceType);

	if (!capabilities) {
		throw new Error('No capabilities registered for resource type');
	}

	return await capabilities?.getNavigationDetails?.(RID);
};
