import { PermissionsConfiguration, UserRole } from '@sharefiledev/permissions-pilet';
import { resolveLocaleString } from '../utils';

export enum TemplatePermissionSetKey {
	Read = 'template-read',
	Share = 'template-share',
	Delete = 'template-delete',
	Edit = 'template-edit',
}

export enum TemplatePermissionPresetRid {
	Full = 'sharefile:template_permission_preset:full_access',
	Read = 'sharefile:template_permission_preset:read',
}

export const templatePermissionsConfig: PermissionsConfiguration = {
	availablePermissionSets: [
		{
			key: TemplatePermissionSetKey.Read,
			description: resolveLocaleString('permissions.template.read'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:read'],
			requiredRoles: [UserRole.Employee],
		},
		{
			key: TemplatePermissionSetKey.Share,
			description: resolveLocaleString('permissions.template.share'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:share'],
			requireAllKeys: [TemplatePermissionSetKey.Read],
		},
		{
			key: TemplatePermissionSetKey.Delete,
			description: resolveLocaleString('permissions.template.delete'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:delete'],
			requireAllKeys: [TemplatePermissionSetKey.Read],
		},
		{
			key: TemplatePermissionSetKey.Edit,
			description: resolveLocaleString('permissions.template.edit'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:modify'],
			requireAllKeys: [TemplatePermissionSetKey.Read],
		},
	],
	presets: [
		{
			rid: TemplatePermissionPresetRid.Full,
			name: resolveLocaleString('permissions.template.all_permissions'),
			permissions: ['*:read', '*:delete', '*:share', '*:modify'],
		},
		{
			rid: TemplatePermissionPresetRid.Read,
			name: resolveLocaleString('prompts.use_template'),
			permissions: ['*:read'],
		},
	],
};
