import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppContextProvider } from './useAppContext';
import { ShareFileContextProvider } from './useShareFileContext';

const queryClient = new QueryClient();

export function withDefaultProviders<T>(piletApi: PiletApi, Wrapped: any) {
	class WithDefaultProviders extends React.Component {
		public render() {
			return (
				<AppContextProvider value={{ piletApi }}>
					<ShareFileContextProvider>
						<QueryClientProvider client={queryClient} contextSharing>
							<Wrapped {...this.props} />
						</QueryClientProvider>
					</ShareFileContextProvider>
				</AppContextProvider>
			);
		}
	}
	return WithDefaultProviders as React.ComponentType<T>;
}
