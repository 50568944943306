import React, { useContext } from 'react';
import { Account, User } from '@citrite/sf-api';
import { useAppContext } from './useAppContext';

export interface ShareFileContext {
	user: User;
	account: Account;
}

const ShareFileContext = React.createContext<ShareFileContext>({
	user: {},
	account: {},
});

export const useShareFileContext = () => {
	return useContext(ShareFileContext);
};

export const ShareFileContextProvider = (props: { children: React.ReactNode }) => {
	const { piletApi } = useAppContext();
	const { user, account } = piletApi.sf.piletUserContext.get();
	return (
		<ShareFileContext.Provider value={{ user, account }}>
			{props.children}
		</ShareFileContext.Provider>
	);
};
