import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { logError } from '../logger';
import { checkASTs, checkFeatureFlags, checkRoles } from './helpers';
import {
	AccountPreferencesWithRequiredASTs,
	RolesAndProvisioningRequirements,
} from './types';

export function checkRolesAndPreferences(
	app: PiletApi,
	options: RolesAndProvisioningRequirements
) {
	try {
		const userContext = app.sf.piletUserContext.get();
		const roles = userContext.user.Roles;
		const preferences = userContext.account.Preferences;
		return (
			checkASTs(
				preferences as AccountPreferencesWithRequiredASTs,
				options.requiredASTs
			) &&
			checkFeatureFlags(options.requiredFeatureFlags) &&
			checkRoles(roles, options.requiredRoles)
		);
	} catch (err) {
		logError(err, {
			customMessage: 'Failed to perform user role and provisioning check',
		});
		return false;
	}
}
