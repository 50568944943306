import AntIcon from '@ant-design/icons';
import { colorPalette } from '@sharefiledev/antd-config';

const TemplateIconSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 3.53846C0 2.13611 1.14492 1 2.55814 1C2.94345 1 3.25581 1.30996 3.25581 1.69231C3.25581 2.07466 2.94345 2.38462 2.55814 2.38462C1.91555 2.38462 1.39535 2.90081 1.39535 3.53846C1.39535 3.92081 1.08299 4.23077 0.697674 4.23077C0.312359 4.23077 0 3.92081 0 3.53846Z"
			fill="inherit"
		/>
		<path
			d="M0.697674 15.7692C1.08299 15.7692 1.39535 16.0792 1.39535 16.4615C1.39535 17.0992 1.91555 17.6154 2.55814 17.6154C2.94345 17.6154 3.25581 17.9253 3.25581 18.3077C3.25581 18.69 2.94345 19 2.55814 19C1.14492 19 0 17.8639 0 16.4615C0 16.0792 0.312359 15.7692 0.697674 15.7692Z"
			fill="inherit"
		/>
		<path
			d="M0.697674 10.8492C1.08299 10.8492 1.39535 11.1591 1.39535 11.5415V13.3876C1.39535 13.77 1.08299 14.0799 0.697674 14.0799C0.312359 14.0799 0 13.77 0 13.3876V11.5415C0 11.1591 0.312359 10.8492 0.697674 10.8492Z"
			fill="inherit"
		/>
		<path
			d="M1.39535 6.61238C1.39535 6.23003 1.08299 5.92007 0.697674 5.92007C0.312359 5.92007 0 6.23003 0 6.61238V8.45853C0 8.84088 0.312359 9.15084 0.697674 9.15084C1.08299 9.15084 1.39535 8.84088 1.39535 8.45853V6.61238Z"
			fill="inherit"
		/>
		<path
			d="M4.74419 18.3077C4.74419 17.9253 5.05654 17.6154 5.44186 17.6154H7.30233C7.68764 17.6154 8 17.9253 8 18.3077C8 18.69 7.68764 19 7.30233 19H5.44186C5.05654 19 4.74419 18.69 4.74419 18.3077Z"
			fill="inherit"
		/>
		<path
			d="M5.5 1C5.11469 1 4.80233 1.30996 4.80233 1.69231C4.80233 2.07466 5.11469 2.38462 5.5 2.38462H7.36047C7.74578 2.38462 8.05814 2.07466 8.05814 1.69231C8.05814 1.30996 7.74578 1 7.36047 1H5.5Z"
			fill="inherit"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.5 2.5H11.5V17.5H18.5V2.5ZM10 1V19H20V1H10Z"
			fill="inherit"
		/>
	</svg>
);

export const TemplateIcon = props => (
	<AntIcon
		{...props}
		component={TemplateIconSvg}
		style={{ fontSize: props.size || 24, fill: props.color || colorPalette.neutral13 }}
	/>
);
