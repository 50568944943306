import AntIcon from '@ant-design/icons';

const WorkflowIconSvg = () => (
	<svg
		width="112"
		height="112"
		viewBox="0 0 112 112"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<path
				d="M28.7759 18.2995C27.5787 17.2275 26.1728 16.4145 24.6466 15.9117V12.414H17.3535V15.9117C15.8272 16.4145 14.4214 17.2275 13.2242 18.2995L10.1903 16.5491L6.54376 22.8648L9.57483 24.6152C9.24776 26.1883 9.24776 27.8117 9.57483 29.3848L6.54523 31.1352L10.1918 37.4509L13.2242 35.7005C14.421 36.7724 15.8263 37.5853 17.352 38.0883V41.586H24.6451V38.0883C26.1719 37.5857 27.5783 36.7728 28.7759 35.7005L31.8098 37.4509L33.633 34.2931L34.0888 33.5036"
				stroke="black"
				strokeWidth="2"
			/>
			<path
				d="M16.6241 27C16.6241 30.3685 20.2707 32.4739 23.1879 30.7896C24.5417 30.0079 25.3759 28.5633 25.3759 27C25.3759 23.6315 21.7293 21.5261 18.8121 23.2104C17.4583 23.9921 16.6241 25.4367 16.6241 27Z"
				stroke="black"
				strokeWidth="2"
			/>
			<rect
				x="32"
				y="8"
				width="48"
				height="22"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="32"
				y="45"
				width="48"
				height="22"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path d="M56 54H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M56 59H64" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<rect
				x="32"
				y="82"
				width="48"
				height="22"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M43.9999 25.0001C47.3136 25.0001 49.9999 22.3137 49.9999 19.0001C49.9999 15.6865 47.3136 13.0001 43.9999 13.0001C40.6863 13.0001 37.9999 15.6865 37.9999 19.0001C37.9999 22.3137 40.6863 25.0001 43.9999 25.0001ZM43.7436 21.3865L47.0163 17.2955L46.1654 16.6137L43.2445 20.2645L41.7641 18.9952L41.0539 19.8232L42.963 21.4595L43.3918 21.8272L43.7436 21.3865Z"
				fill="#0C0D0D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M43.9999 62.0001C47.3136 62.0001 49.9999 59.3137 49.9999 56.0001C49.9999 52.6865 47.3136 50.0001 43.9999 50.0001C40.6863 50.0001 37.9999 52.6865 37.9999 56.0001C37.9999 59.3137 40.6863 62.0001 43.9999 62.0001ZM43.7436 58.3865L47.0163 54.2955L46.1654 53.6137L43.2445 57.2645L41.7641 55.9952L41.0539 56.8232L42.963 58.4595L43.3918 58.8272L43.7436 58.3865Z"
				fill="#0C0D0D"
			/>
			<circle cx="44" cy="93" r="5" stroke="#0C0D0D" strokeWidth="2" />
			<path d="M56 17H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M56 22H64" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M56 91H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M56 96H64" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path
				d="M80 20H92C101.941 20 110 28.0589 110 38V38C110 47.9411 101.941 56 92 56H87"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M91.9112 48L84 56L91.9112 64L93.2744 62.6215L87.6904 56.9748V55.0252L93.2744 49.3785L91.9112 48Z"
				fill="#0C0D0D"
			/>
			<path
				d="M32 56H20C10.0589 56 2 64.0589 2 74V74C2 83.9411 10.0589 92 20 92H25"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M20.0888 84L28 92L20.0888 100L18.7256 98.6215L24.3096 92.9748V91.0252L18.7256 85.3785L20.0888 84Z"
				fill="#0C0D0D"
			/>
		</g>
	</svg>
);

export const WorkflowIcon = props => <AntIcon {...props} component={WorkflowIconSvg} />;
