export const localizeResourceToken = (
	resourceType: string,
	token: string,
	verticalContextKey?: string
) => {
	const resourceTypeWithContext = verticalContextKey
		? `${resourceType.replace(':', '_')}_${verticalContextKey}`
		: `${resourceType.replace(':', '_')}`;
	return `resource.${resourceTypeWithContext}.${token}`;
};
