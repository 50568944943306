import { UserRole } from '@citrite/sf-api';

export function checkRoles(roles?: UserRole[], requiredRoles?: UserRole[]) {
	if (!!!requiredRoles) {
		return true;
	}

	const rolesDict = (roles ?? []).reduce((acc, ele) => {
		return { ...acc, [ele]: true };
	}, {});

	return requiredRoles.reduce((acc, role) => {
		return acc && !!rolesDict[role];
	}, true);
}
