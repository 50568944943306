import { PiletApi } from '@sharefiledev/sharefile-appshell';

const DOCGEN_ENTITLEMENT_CREATE_TEMPLATE = 'sharefile:entitlementdef-create-doctemplate';

export function isEntitledToCreateDocgenTemplate(piletApi: PiletApi) {
	const sfUserContext = piletApi.sf.piletUserContext.get();
	return sfUserContext.entitlements.getEntitlementStatus(
		DOCGEN_ENTITLEMENT_CREATE_TEMPLATE
	);
}
