import AntIcon from '@ant-design/icons';

const EmptyErrorIconSvg = () => (
	<svg
		width="120"
		height="120"
		viewBox="0 0 120 120"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_2972_17898)">
			<path
				d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
				fill="#FFF1E8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M53.7148 36.8583L80.1825 80.971C80.7507 81.9181 80.4436 83.1467 79.4965 83.7149C79.1856 83.9014 78.83 84 78.4675 84H25.5322C24.4277 84 23.5322 83.1045 23.5322 82C23.5322 81.6375 23.6307 81.2818 23.8172 80.971L50.2849 36.8583C50.8532 35.9111 52.0817 35.604 53.0288 36.1723C53.3103 36.3412 53.5459 36.5768 53.7148 36.8583Z"
				fill="#FFD5B8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M62.7148 31.8583L89.1824 75.971C89.7507 76.9181 89.4436 78.1467 88.4965 78.715C88.1856 78.9014 87.83 79 87.4675 79H34.5322C33.4277 79 32.5322 78.1045 32.5322 77C32.5322 76.6375 32.6307 76.2818 32.8172 75.971L59.2849 31.8583C59.8532 30.9111 61.0817 30.604 62.0288 31.1723C62.3103 31.3412 62.5459 31.5768 62.7148 31.8583Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M61.0001 47C62.1011 47 62.9937 47.8926 62.9937 48.9937C62.9937 49.0326 62.9926 49.0716 62.9903 49.1105L62.1124 64.0586C62.0814 64.5872 61.6437 65 61.1141 65H60.886C60.3565 65 59.9187 64.5872 59.8877 64.0586L59.0098 49.1105C58.9453 48.0114 59.784 47.068 60.8832 47.0034C60.9221 47.0011 60.9611 47 61.0001 47Z"
				fill="#D15700"
			/>
			<path
				d="M61 71C62.1046 71 63 70.1046 63 69C63 67.8954 62.1046 67 61 67C59.8954 67 59 67.8954 59 69C59 70.1046 59.8954 71 61 71Z"
				fill="#D15700"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M62.0288 31.1723C62.3103 31.3412 62.5459 31.5768 62.7148 31.8583L89.1824 75.971C89.7507 76.9181 89.4436 78.1467 88.4965 78.715C88.1856 78.9014 87.83 79 87.4675 79H34.5322C33.4277 79 32.5322 78.1045 32.5322 77C32.5322 76.6375 32.6307 76.2818 32.8172 75.971L59.2849 31.8583C59.8532 30.9111 61.0817 30.604 62.0288 31.1723ZM60.4853 33.9734C60.3446 34.0579 60.2268 34.1757 60.1424 34.3164L35.4409 75.4855C35.1568 75.959 35.3103 76.5733 35.7839 76.8575C35.9393 76.9507 36.1172 77 36.2984 77H85.7013C86.2536 77 86.7013 76.5522 86.7013 76C86.7013 75.8187 86.652 75.6409 86.5588 75.4855L61.8573 34.3164C61.5732 33.8428 60.9589 33.6893 60.4853 33.9734Z"
				fill="#D15700"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2972_17898">
				<rect width="120" height="120" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const EmptyErrorIcon = props => (
	<AntIcon {...props} component={EmptyErrorIconSvg} />
);
