import AntIcon from '@ant-design/icons';

const InformationRequestIconSvg = () => (
	<svg
		width="78"
		height="91"
		viewBox="0 0 78 91"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<rect
				x="1"
				y="5.5"
				width="68"
				height="84"
				rx="2"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="1"
				y="5.5"
				width="68"
				height="4"
				rx="2"
				fill="black"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="5"
				y="5.5"
				width="60"
				height="80"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="11"
				y="1.5"
				width="48"
				height="8"
				rx="4"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path d="M12 21.5H59" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M12 26.5H59" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M12 31.5H59" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M12 36.5H59" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M12 41.5H43" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<circle cx="65" cy="65.5" r="12" fill="black" stroke="#0C0D0D" strokeWidth="2" />
			<circle cx="65" cy="65.5" r="12" fill="black" stroke="#0C0D0D" strokeWidth="2" />
			<path
				d="M66.328 62.028C65.576 62.028 64.984 61.436 64.984 60.668C64.984 59.916 65.576 59.356 66.328 59.356C67.096 59.356 67.656 59.916 67.656 60.668C67.656 61.436 67.096 62.028 66.328 62.028ZM65.224 71.5H63.032L64.744 63.02H66.936L65.224 71.5Z"
				fill="white"
			/>
			<path d="M23 55.5H44" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M23 60.5H37" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<rect
				x="12"
				y="54.5"
				width="7"
				height="7"
				rx="1"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path d="M23 67.5H44" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M23 72.5H37" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<rect
				x="12"
				y="66.5"
				width="7"
				height="7"
				rx="1"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</g>
	</svg>
);

export const InformationRequestIcon = props => (
	<AntIcon {...props} component={InformationRequestIconSvg} />
);
