import AntIcon from '@ant-design/icons';

const DocumentIconSvg = () => (
	<svg
		width="92"
		height="97"
		viewBox="0 0 92 97"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.5">
			<rect
				x="23"
				y="11.5"
				width="68"
				height="84"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<rect
				x="18"
				y="6.5"
				width="68"
				height="84"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path
				d="M13 1.5H65L81 17.5V85.5H13V1.5Z"
				fill="white"
				stroke="#0C0D0D"
				strokeWidth="2"
			/>
			<path d="M65 6.5H13V1.5H65V6.5Z" fill="black" />
			<path d="M81 17.5H65V1.5" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<circle cx="13" cy="68.5" r="12" fill="black" stroke="#0C0D0D" strokeWidth="2" />
			<path d="M13 63.5V73.5" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M18 68.5H8" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 32.5H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 37.5H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 17.5H38" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 22.5H29" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 42.5H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 47.5H73" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M21 52.5H55" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
		</g>
	</svg>
);

export const DocumentIcon = props => <AntIcon {...props} component={DocumentIconSvg} />;
