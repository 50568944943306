/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "common": {
        "actions": "Aktionen",
        "all_categories": "Alle Kategorien",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "change": "Ändern",
        "close": "Schließen",
        "copy": "Kopieren",
        "create": "Erstellen",
        "created_by": "Erstellt von",
        "created_on": "Erstellt am",
        "delete": "Löschen",
        "description": "Beschreibung",
        "details": "Details",
        "duplicate": "Duplizieren",
        "duplicating": "Duplizieren",
        "edit": "Bearbeiten",
        "more": "Mehr",
        "my_templates": "Eigene Vorlagen",
        "name": "Name",
        "optional": "Optional",
        "preview": "Vorschau",
        "save": "Speichern",
        "search": "Suchen",
        "select": "Auswählen",
        "shared_with_me": "Für mich freigegeben",
        "solution_templates": "Lösungsvorlagen",
        "template": "Vorlage",
        "template_name": "Vorlagenname",
        "template_properties": "Vorlageneigenschaften",
        "templates": "Vorlagen",
        "unknown": "Unbekannt"
      },
      "messages": {
        "browse_catalog": "Katalog durchsuchen",
        "browse_catalog_description": "Durchsuchen Sie den Katalog nach weiteren Vorlagen.",
        "couldnt_load_template": "Wir konnten Ihre Vorlage nicht laden.",
        "couldnt_load_templates": "Wir konnten Ihre Vorlagen nicht laden.",
        "couldnt_save_changes": "Ihre Änderungen konnten nicht gespeichert werden",
        "create_resource": "Erstellen {{resourceTypeLabel}}",
        "created_template": "Erstellte Vorlage",
        "draft_state": "Ihre Vorlage wurde erstellt, befindet sich jedoch aufgrund möglicher Probleme beim Abrufen von Ressourcen derzeit im Entwurfstatus.",
        "delete_failure_multiple": "Ihre Vorlagen konnten nicht gelöscht werden",
        "delete_failure_single": "Die Vorlage {{name}} konnte nicht gelöscht werden",
        "delete_success_multiple": "{{count}} Vorlagen gelöscht",
        "delete_success_single": "Gelöschte Vorlage",
        "editing_parameters": "Wählen Sie beliebige Parameterwerte aus oder geben Sie sie ein, um zu beginnen. Diese Werte werden nur als Beispieldaten verwendet, wenn Sie Ihre Vorlage anpassen.",
        "editing_properties": "Bearbeiten Sie alles, was mit dieser Vorlage einhergeht, oder erstellen Sie etwas Neues.",
        "empty": {
          "error_loading_templates": {
            "title": "Ihre Vorlagen konnten nicht geladen werden"
          },
          "no_category_results": {
            "title": "Sie haben keine Vorlagen vom Typ {{resourceType}}"
          },
          "no_search_results": {
            "title": "Ihre Suche stimmt mit keiner Vorlage überein",
            "subtitle": "{{term}} konnte weder in Titeln noch in Beschreibungen von Vorlagen gefunden werden.",
            "action_label": "Suche löschen"
          },
          "my_templates": {
            "title": "Sie haben noch keine anwendbaren Vorlagen erstellt",
            "subtitle": "Die von Ihnen erstellten Vorlagen werden hier gespeichert."
          },
          "my_templates_ftu": {
            "title": "Willkommen in Ihrer Vorlagenbibliothek",
            "subtitle": "Beginnen Sie mit dem Einrichten Ihrer Bibliothek, indem Sie eine Vorlage erstellen.",
            "action_message": "Weitere Vorlagenoptionen werden demnächst bereitgestellt"
          },
          "shared_templates": {
            "title": "Sie haben noch keine anwendbaren freigegebenen Vorlagen",
            "subtitle": "Von anderen erstellte und für Sie freigegebene Vorlagen bleiben hier gespeichert."
          },
          "shared_templates_ftu": {
            "title": "Freigegebene Vorlagen",
            "subtitle": "Hier finden Sie Vorlagen, die andere Benutzer erstellt haben."
          },
          "installed_templates": {
            "title": "Sie haben noch keine anwendbaren installierten Vorlagen",
            "subtitle": "Hier werden die aus dem Katalog installierten und verwendbaren Vorlagen gespeichert.",
            "action_label": "Zum Katalog"
          },
          "installed_templates_ftu": {
            "title": "Installierte Vorlagen",
            "subtitle": "Die aus dem Katalog installierten Vorlagen verbleiben hier.",
            "action_label": "Zum Katalog"
          }
        },
        "generic_error": "Es ist ein Fehler aufgetreten.",
        "hint_text_template_name": "Dieser Name wird im Vorlagenkatalog angezeigt.",
        "loading_template": "Die Vorlagendetails werden geladen.",
        "loading_templates": "Ihre Vorlagen werden geladen.",
        "no_templates_available": "Es sind keine Vorlagen verfügbar.",
        "run_manually": "Sie können diese Vorlage manuell mit der folgenden RID ausführen: ${RID}",
        "template_duplicate_disabled": "Diese Vorlage kann nicht dupliziert werden.",
        "template_duplicate_error": "Ihre Vorlage konnte nicht dupliziert werden",
        "template_duplicate_success_management": "Ihre Vorlage wurde dupliziert. Navigieren Sie zur Registerkarte 'Eigene Vorlagen', um sie zu verwenden und zu bearbeiten.",
        "template_edit_disabled": {
          "insufficient_permissions": "Sie sind nicht berechtigt, diese Vorlage zu bearbeiten.",
          "installed_template": {
            "default": "Vorlagen, die mit Lösungen geliefert werden, können nicht bearbeitet werden. Duplizieren Sie diese Vorlage, um eine Version zu erstellen, die Sie bearbeiten können.",
            "duplicate_disabled": "Mit Lösungen gelieferte Vorlagen können nicht bearbeitet werden."
          }
        },
        "template_run_failed": "Wir konnten Ihr {{resourceTypeLabel}} nicht erstellen. Bitte versuchen Sie es erneut.",
        "template_run_success": "{{resourceTypeLabel}} erstellt",
        "this_field_is_required": "Dieses Feld ist erforderlich",
        "try_update_properties_again": "Versuchen Sie erneut, Ihre Vorlageneigenschaften zu aktualisieren.",
        "unable_to_create_template": "Ihre Vorlage konnte nicht erstellt werden. Versuchen Sie es erneut.",
        "unable_to_redirect": "Das Navigieren zu {{resourceTypeLabel}} war nicht möglich",
        "updated_template_properties": "Aktualisierte Vorlageneigenschaften",
        "what_is_copied": "Was kopiert wird",
        "what_is_not_copied": "Was nicht kopiert wird"
      },
      "permissions": {
        "doc_template": {
          "delete": "Kann löschen",
          "modify": "Kann ändern",
          "presets": {
            "contributor": "Mitwirkender",
            "creator": "Ersteller",
            "viewer": "Leseberechtigter"
          },
          "share": "Kann freigeben",
          "source": "Für diese Vorlage",
          "view": "Kann anzeigen"
        },
        "template": {
          "all_permissions": "Volle Kontrolle",
          "delete": "Kann die Vorlage löschen",
          "edit": "Kann die Vorlage bearbeiten",
          "read": "Kann die Vorlage verwenden",
          "share": "Kann den Zugriff auf die Vorlage verwalten"
        }
      },
      "prompts": {
        "choose_a_template": "Vorlage auswählen",
        "create_template": "Vorlage erstellen",
        "delete_confirmation_multiple": "Möchten Sie {{count}} Vorlagen wirklich löschen? Diese Vorlagen werden dauerhaft gelöscht. Wenn diese Vorlagen freigegeben werden, verlieren Benutzer den Zugriff.",
        "delete_confirmation_single": "Möchten Sie die Vorlage {{name}} wirklich löschen? Die Vorlage wird dauerhaft gelöscht. Wenn diese Vorlage freigegeben wurde, verlieren die Benutzer ihren Zugriff.",
        "delete_template": "Vorlage löschen",
        "duplicate": "Duplizieren",
        "edit_template": "Vorlage bearbeiten",
        "manage_access": "Zugriff verwalten",
        "save_as_template": "Als Vorlage speichern",
        "save_changes": "Änderungen speichern",
        "select_a_template": "Vorlage auswählen",
        "use_template": "Vorlage verwenden",
        "use_a_template": "Vorlage verwenden",
        "view_details": "Details anzeigen"
      },
      "resource": {
        "type_template": "Vorlage vom Typ {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabelle",
          "label": "Tabelle",
          "preview": "Tabellendetails",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Speichern Sie diese Datentabelle als Vorlage, die zum Erstellen neuer Tabellen verwendet werden kann."
          }
        },
        "sharefile_document": {
          "category_label": "Dokument",
          "label": "Dokument",
          "preview": "Dokumentdetails"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "Workflow",
          "preview": "Workflowdetails",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Speichern Sie diesen Workflow als Vorlage, die zum Erstellen neuer Workflows verwendet werden kann."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Informationsanforderungen",
          "label": "Informationsanforderung",
          "preview": "Details zur Informationsanforderung",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Speichern Sie diese Informationsanforderung als Vorlage, die Sie zum Erstellen neuer Anforderungen verwenden können."
          }
        },
        "sharefile_project": {
          "category_label": "Projekte",
          "label": "Projekt",
          "preview": "Projektdetails",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Speichern Sie dieses Projekt als Vorlage, die zum Erstellen neuer Projekte verwendet werden kann."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Auftrag",
          "label": "Auftrag",
          "preview": "Auftragsdetails",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Speichern Sie diesen Auftrag als Vorlage, die zum Erstellen neuer Aufträge verwendet werden kann."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Rechtsangelegenheit",
          "label": "Rechtsangelegenheit",
          "preview": "Details der Rechtsangelegenheit",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Speichern Sie diese Rechtsangelegenheit als Vorlage, die zum Erstellen neuer Rechtsangelegenheiten verwendet werden kann."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Dokumentanforderungen",
          "label": "Dokumentanforderung",
          "preview": "Details der Dokumentanforderung",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Speichern Sie diese Dokumentanforderung als Vorlage, die Sie zum Erstellen neuer Dokumentanforderungen verwenden können."
          }
        }
      },
      "titles": {
        "dashboard": "Vorlagen"
      }
    }
  },
  {
    "key": "en",
    "content": {
      "common": {
        "actions": "Actions",
        "all_categories": "All categories",
        "back": "Back",
        "cancel": "Cancel",
        "change": "Change",
        "close": "Close",
        "copy": "Copy",
        "create": "Create",
        "created_by": "Created by",
        "created_on": "Created on",
        "delete": "Delete",
        "description": "Description",
        "details": "Details",
        "duplicate": "Duplicate",
        "duplicating": "Duplicating",
        "edit": "Edit",
        "more": "More",
        "my_templates": "My templates",
        "name": "Name",
        "optional": "optional",
        "preview": "Preview",
        "save": "Save",
        "search": "Search",
        "select": "Select",
        "shared_with_me": "Shared with me",
        "solution_templates": "Solution templates",
        "template": "Template",
        "template_name": "Template name",
        "template_properties": "Template properties",
        "templates": "Templates",
        "unknown": "Unknown"
      },
      "messages": {
        "browse_catalog": "Browse catalog",
        "browse_catalog_description": "Browse the catalog for more templates.",
        "couldnt_load_template": "We couldn't load your template.",
        "couldnt_load_templates": "We couldn't load your templates.",
        "couldnt_save_changes": "We couldn't save your changes",
        "create_resource": "Create {{resourceTypeLabel}}",
        "created_template": "Created template",
        "draft_state": "Your template was created, but is currently in a draft state due to potential issues with resource fetching.",
        "delete_failure_multiple": "We couldn't delete your templates",
        "delete_failure_single": "We couldn't delete the {{name}} template",
        "delete_success_multiple": "Deleted {{count}} templates",
        "delete_success_single": "Deleted template",
        "editing_parameters": "Select or enter any parameter values to get started. These values will only be used as sample data as you customize your template.",
        "editing_properties": "Edit anything that comes with this template, or create something new.",
        "empty": {
          "error_loading_templates": {
            "title": "We couldn’t load your templates"
          },
          "no_category_results": {
            "title": "You don't have any {{resourceType}} templates"
          },
          "no_search_results": {
            "title": "Your search doesn’t match any templates",
            "subtitle": "We couldn't find {{term}} in any template titles or descriptions.",
            "action_label": "Clear search"
          },
          "my_templates": {
            "title": "You haven't created any applicable templates yet",
            "subtitle": "Templates you create will live here."
          },
          "my_templates_ftu": {
            "title": "Welcome to your template library",
            "subtitle": "Start to build your library by creating a template.",
            "action_message": "More template options coming soon"
          },
          "shared_templates": {
            "title": "You do not have any applicable shared templates yet",
            "subtitle": "Templates created by others and shared with you will live here."
          },
          "shared_templates_ftu": {
            "title": "Shared templates",
            "subtitle": "Here you’ll be able to find templates that other users have created."
          },
          "installed_templates": {
            "title": "You do not have any applicable installed templates yet",
            "subtitle": "Templates installed from the catalog that can be used will live here.",
            "action_label": "Go to catalog"
          },
          "installed_templates_ftu": {
            "title": "Installed templates",
            "subtitle": "Templates you install from the catalog will live here.",
            "action_label": "Go to catalog"
          }
        },
        "generic_error": "An error occurred.",
        "hint_text_template_name": "This name will show up in the template gallery.",
        "loading_template": "Template details are loading.",
        "loading_templates": "Your templates are loading.",
        "no_templates_available": "There are no templates available.",
        "run_manually": "You can run this template manually using the following RID: ${RID}",
        "template_duplicate_disabled": "This template can't be duplicated.",
        "template_duplicate_error": "We couldn't duplicate your template",
        "template_duplicate_success_management": "Your template has been duplicated. Navigate to the 'My templates' tab to use and edit it.",
        "template_edit_disabled": {
          "insufficient_permissions": "You don't have permission to edit this template.",
          "installed_template": {
            "default": "Templates that come with solutions can't be edited. Duplicate this template to create a version you can edit.",
            "duplicate_disabled": "Templates that come with solutions can't be edited."
          }
        },
        "template_run_failed": "We couldn't create your {{resourceTypeLabel}}. Please try again.",
        "template_run_success": "Created {{resourceTypeLabel}}",
        "this_field_is_required": "This field is required",
        "try_update_properties_again": "Please try again to update your template properties.",
        "unable_to_create_template": "We couldn't create your template. Please try again.",
        "unable_to_redirect": "We couldn't navigate to your {{resourceTypeLabel}}",
        "updated_template_properties": "Updated template properties",
        "what_is_copied": "What will be copied",
        "what_is_not_copied": "What won't be copied"
      },
      "permissions": {
        "doc_template": {
          "delete": "Can delete",
          "modify": "Can modify",
          "presets": {
            "contributor": "Contributor",
            "creator": "Creator",
            "viewer": "Viewer"
          },
          "share": "Can share",
          "source": "For this template",
          "view": "Can view"
        },
        "template": {
          "all_permissions": "Full control",
          "delete": "Can delete the template",
          "edit": "Can edit the template",
          "read": "Can use the template",
          "share": "Can manage access to the template"
        }
      },
      "prompts": {
        "choose_a_template": "Choose a template",
        "create_template": "Create template",
        "delete_confirmation_multiple": "Are you sure you want to delete {{count}} templates? These templates will be permanently deleted. If these templates are shared, users will lose access.",
        "delete_confirmation_single": "Are you sure you want to delete the {{name}} template? The template will be permanently deleted. If this template is shared, users will lose access.",
        "delete_template": "Delete template",
        "duplicate": "Duplicate",
        "edit_template": "Edit template",
        "manage_access": "Manage access",
        "save_as_template": "Save as template",
        "save_changes": "Save changes",
        "select_a_template": "Select a template",
        "use_template": "Use template",
        "use_a_template": "Use a template",
        "view_details": "View details"
      },
      "resource": {
        "type_template": "{{type}} template",
        "sharefile_datatable": {
          "category_label": "Table",
          "label": "table",
          "preview": "Table details",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Save this data table as a template that can be used to create new tables."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Document details"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "workflow",
          "preview": "Workflow details",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Save this workflow as a template that can be used to create new workflows."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Information requests",
          "label": "information request",
          "preview": "Information Request details",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Save this information request as a template that can be used to create new requests."
          }
        },
        "sharefile_project": {
          "category_label": "Projects",
          "label": "project",
          "preview": "Project details",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Save this project as a template that can be used to create new projects."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Engagement",
          "label": "engagement",
          "preview": "Engagement details",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Save this engagement as a template that can be used to create new engagements."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Matter",
          "label": "Matter",
          "preview": "Matter details",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Save this matter as a template that can be used to create new matters."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Document requests",
          "label": "document request",
          "preview": "Document request details",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Save this document request as a template that can be used to create new document requests."
          }
        }
      },
      "titles": {
        "dashboard": "Templates"
      }
    }
  },
  {
    "key": "es",
    "content": {
      "common": {
        "actions": "Acciones",
        "all_categories": "Todas las categorias",
        "back": "Atrás",
        "cancel": "Cancelar",
        "change": "Cambiar",
        "close": "Cerrar",
        "copy": "Copiar",
        "create": "Crear",
        "created_by": "Creado por",
        "created_on": "Creadas el",
        "delete": "Eliminar",
        "description": "Descripción",
        "details": "Detalles",
        "duplicate": "Duplicar",
        "duplicating": "Duplicando",
        "edit": "Modificar",
        "more": "Más",
        "my_templates": "Mis plantillas",
        "name": "Nombre",
        "optional": "opcional",
        "preview": "Vista previa",
        "save": "Guardar",
        "search": "Buscar",
        "select": "Seleccionar",
        "shared_with_me": "Compartidos conmigo",
        "solution_templates": "Plantillas de soluciones",
        "template": "Plantilla",
        "template_name": "Nombre de plantilla",
        "template_properties": "Propiedades de plantilla",
        "templates": "Plantillas",
        "unknown": "Desconocido"
      },
      "messages": {
        "browse_catalog": "Examinar catálogo",
        "browse_catalog_description": "Examine el catálogo en busca de otras plantillas.",
        "couldnt_load_template": "No pudimos cargar su plantilla.",
        "couldnt_load_templates": "No pudimos cargar sus plantillas.",
        "couldnt_save_changes": "No pudimos guardar los cambios",
        "create_resource": "Crear {{resourceTypeLabel}}",
        "created_template": "Plantilla creada",
        "draft_state": "La plantilla se creó, pero actualmente se encuentra en estado de borrador debido a posibles problemas con la obtención de recursos.",
        "delete_failure_multiple": "No pudimos eliminar sus plantillas",
        "delete_failure_single": "No pudimos eliminar la plantilla {{name}}",
        "delete_success_multiple": "{{count}} plantillas eliminadas",
        "delete_success_single": "Plantilla eliminada",
        "editing_parameters": "Seleccione o introduzca los valores de los parámetros para empezar. Estos valores solo se usarán como datos de muestra a medida que personalice su plantilla.",
        "editing_properties": "Modifique todo lo que viene con esta plantilla o cree algo nuevo.",
        "empty": {
          "error_loading_templates": {
            "title": "No pudimos cargar sus plantillas"
          },
          "no_category_results": {
            "title": "No tiene ninguna plantilla de {{resourceType}}"
          },
          "no_search_results": {
            "title": "Su búsqueda no coincide con ninguna plantilla",
            "subtitle": "No pudimos encontrar {{term}} en los títulos o descripciones de ninguna plantilla.",
            "action_label": "Borrar búsqueda"
          },
          "my_templates": {
            "title": "Aún no ha creado ninguna plantilla aplicable",
            "subtitle": "Las plantillas que cree residirán aquí."
          },
          "my_templates_ftu": {
            "title": "Le damos la bienvenida a su biblioteca de plantillas",
            "subtitle": "Cree una plantilla para empezar a construir su biblioteca.",
            "action_message": "Próximamente habrá más opciones de plantilla"
          },
          "shared_templates": {
            "title": "Aún no tiene ninguna plantilla compartida aplicable",
            "subtitle": "Las plantillas creadas por otros usuarios y compartidas con usted residirán aquí."
          },
          "shared_templates_ftu": {
            "title": "Plantillas compartidas",
            "subtitle": "Aquí podrá encontrar plantillas que otros usuarios han creado."
          },
          "installed_templates": {
            "title": "Aún no tiene ninguna plantilla instalada aplicable",
            "subtitle": "Las plantillas instaladas del catálogo que se pueden usar residirán aquí.",
            "action_label": "Ir al catalogo"
          },
          "installed_templates_ftu": {
            "title": "Plantillas instaladas",
            "subtitle": "Las plantillas que instale del catálogo residirán aquí.",
            "action_label": "Ir al catalogo"
          }
        },
        "generic_error": "Se ha producido un error.",
        "hint_text_template_name": "Este nombre aparecerá en la galería de plantillas.",
        "loading_template": "Se están cargando los detalles de la plantilla.",
        "loading_templates": "Sus plantillas se están cargando.",
        "no_templates_available": "No hay plantillas disponibles.",
        "run_manually": "Puede ejecutar esta plantilla manualmente con el siguiente RID: ${RID}",
        "template_duplicate_disabled": "Esta plantilla no se puede duplicar.",
        "template_duplicate_error": "No pudimos duplicar su plantilla",
        "template_duplicate_success_management": "Su plantilla se ha duplicado. Vaya a la ficha \"Mis plantillas\" para usarla y modificarla.",
        "template_edit_disabled": {
          "insufficient_permissions": "No tiene permiso para modificar esta plantilla.",
          "installed_template": {
            "default": "Las plantillas que vienen con soluciones no se pueden modificar. Duplique esta plantilla para crear una versión que pueda modificar.",
            "duplicate_disabled": "Las plantillas que vienen con soluciones no se pueden modificar."
          }
        },
        "template_run_failed": "No pudimos crear su {{resourceTypeLabel}}. Inténtelo de nuevo.",
        "template_run_success": "Se creó {{resourceTypeLabel}}",
        "this_field_is_required": "Este campo es obligatorio",
        "try_update_properties_again": "Intente nuevamente actualizar las propiedades de la plantilla.",
        "unable_to_create_template": "No pudimos crear su plantilla. Inténtelo de nuevo.",
        "unable_to_redirect": "No pudimos ir a su {{resourceTypeLabel}}",
        "updated_template_properties": "Propiedades de plantilla actualizadas",
        "what_is_copied": "Lo que se copiará",
        "what_is_not_copied": "Lo que no se copiará"
      },
      "permissions": {
        "doc_template": {
          "delete": "Puede eliminar",
          "modify": "Puede modificar",
          "presets": {
            "contributor": "Colaborador",
            "creator": "Creador",
            "viewer": "Participante"
          },
          "share": "Puede compartir",
          "source": "Para esta plantilla",
          "view": "Puede ver"
        },
        "template": {
          "all_permissions": "Control total",
          "delete": "Puede eliminar la plantilla",
          "edit": "Puede modificar la plantilla",
          "read": "Puede usar la plantilla",
          "share": "Puede administrar el acceso a la plantilla"
        }
      },
      "prompts": {
        "choose_a_template": "Elija una plantilla",
        "create_template": "Crear plantilla",
        "delete_confirmation_multiple": "¿Seguro que quiere eliminar {{count}} plantillas? Estas plantillas se eliminarán de forma permanente. Si estas plantillas se comparten, los usuarios no podrán acceder.",
        "delete_confirmation_single": "¿Seguro que quiere eliminar la plantilla {{name}}? La plantilla se eliminará de forma permanente. Si esta plantilla se comparte, los usuarios no podrán acceder.",
        "delete_template": "Eliminar plantilla",
        "duplicate": "Duplicar",
        "edit_template": "Modificar plantilla",
        "manage_access": "Administrar acceso",
        "save_as_template": "Guardar como plantilla",
        "save_changes": "Guardar cambios",
        "select_a_template": "Seleccionar una plantilla",
        "use_template": "Usar plantilla",
        "use_a_template": "Usar una plantilla",
        "view_details": "Ver detalles"
      },
      "resource": {
        "type_template": "Plantilla {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabla",
          "label": "tabla",
          "preview": "Detalles de la tabla",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Guarde esta tabla de datos como plantilla que puede usar para crear tablas."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Detalles del documento"
        },
        "sharefile_flow": {
          "category_label": "Flujos de trabajo",
          "label": "flujo de trabajo",
          "preview": "Detalles del flujo de trabajo",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Guarde este flujo de trabajo como plantilla que puede usar para crear flujos de trabajo."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Solicitudes de información",
          "label": "solicitud de información",
          "preview": "Detalles de la solicitud de información",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Guarde esta solicitud de información como plantilla que puede usar para crear solicitudes."
          }
        },
        "sharefile_project": {
          "category_label": "Proyectos",
          "label": "proyecto",
          "preview": "Detalles del proyecto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Guarde este proyecto como plantilla que puede usar para crear proyectos."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Compromiso",
          "label": "compromiso",
          "preview": "Detalles del compromiso",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Guarde este compromiso como plantilla que puede usar para crear compromisos."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Asunto",
          "label": "Asunto",
          "preview": "Detalles del asunto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Guarde este asunto como plantilla que puede usar para crear asuntos."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Solicitudes de documentos",
          "label": "solicitud de documento",
          "preview": "Detalles de la solicitud de documento",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Guarde esta solicitud de documento como plantilla que puede usar para crear solicitudes de documentos."
          }
        }
      },
      "titles": {
        "dashboard": "Plantillas"
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "common": {
        "actions": "Actions",
        "all_categories": "Toutes les catégories",
        "back": "Précédent",
        "cancel": "Annuler",
        "change": "Modifier",
        "close": "Fermer",
        "copy": "Copier",
        "create": "Créer",
        "created_by": "Créé par",
        "created_on": "Créé le",
        "delete": "Supprimer",
        "description": "Description",
        "details": "Détails",
        "duplicate": "Dupliquer",
        "duplicating": "Duplication en cours",
        "edit": "Modifier",
        "more": "Plus",
        "my_templates": "Mes modèles",
        "name": "Nom",
        "optional": "facultatif",
        "preview": "Aperçu",
        "save": "Enregistrer",
        "search": "Rechercher",
        "select": "Sélectionner",
        "shared_with_me": "Partagés avec moi",
        "solution_templates": "Modèles de solution",
        "template": "Modèle",
        "template_name": "Nom du modèle",
        "template_properties": "Propriétés du modèle",
        "templates": "Modèles",
        "unknown": "Inconnu"
      },
      "messages": {
        "browse_catalog": "Parcourir le catalogue",
        "browse_catalog_description": "Parcourez le catalogue pour rechercher d'autres modèles.",
        "couldnt_load_template": "Nous n'avons pas pu charger votre modèle.",
        "couldnt_load_templates": "Nous n'avons pas pu charger vos modèles.",
        "couldnt_save_changes": "Nous n'avons pas pu enregistrer vos modifications",
        "create_resource": "Créer {{resourceTypeLabel}}",
        "created_template": "Modèle créé",
        "draft_state": "Le modèle a bien été créé, mais il est actuellement à l'état de brouillon en raison de problèmes potentiels liés à la récupération des ressources.",
        "delete_failure_multiple": "Impossible de supprimer vos modèles",
        "delete_failure_single": "Impossible de supprimer le modèle {{name}}",
        "delete_success_multiple": "{{count}} modèles supprimés",
        "delete_success_single": "Modèle supprimé",
        "editing_parameters": "Pour commencer, sélectionnez ou entrez des valeurs de paramètre. Elles serviront uniquement d'exemples de données lorsque vous personnaliserez votre modèle.",
        "editing_properties": "Modifiez tous les éléments de ce modèle ou créez-en un autre.",
        "empty": {
          "error_loading_templates": {
            "title": "Nous n'avons pas pu charger vos modèles"
          },
          "no_category_results": {
            "title": "Vous ne disposez d'aucun modèle de type {{resourceType}}"
          },
          "no_search_results": {
            "title": "Aucun modèle ne correspond à votre recherche",
            "subtitle": "Nous n'avons trouvé le terme {{term}} dans aucun titre ni description de modèle.",
            "action_label": "Effacer la recherche"
          },
          "my_templates": {
            "title": "Vous n'avez pas encore créé de modèles applicables",
            "subtitle": "Les modèles que vous créez seront disponibles ici."
          },
          "my_templates_ftu": {
            "title": "Bienvenue dans votre bibliothèque de modèles",
            "subtitle": "Commencez à construire votre bibliothèque en créant un modèle.",
            "action_message": "Davantage d'options de modèle bientôt disponibles"
          },
          "shared_templates": {
            "title": "Vous n'avez pas encore de modèles partagés applicables",
            "subtitle": "Les modèles créés par d'autres personnes et partagés avec vous seront disponibles ici."
          },
          "shared_templates_ftu": {
            "title": "Modèles partagés",
            "subtitle": "Ici vous trouverez des modèles créés par d'autres utilisateurs."
          },
          "installed_templates": {
            "title": "Vous n'avez pas encore de modèles installés applicables",
            "subtitle": "Les modèles installés à partir du catalogue et qui peuvent être utilisés seront disponibles ici.",
            "action_label": "Accéder au catalogue"
          },
          "installed_templates_ftu": {
            "title": "Modèles installés",
            "subtitle": "Les modèles que vous installez à partir du catalogue seront disponibles ici.",
            "action_label": "Accéder au catalogue"
          }
        },
        "generic_error": "Une erreur s’est produite.",
        "hint_text_template_name": "Ce nom apparaîtra dans la galerie de modèles.",
        "loading_template": "Les détails du modèle sont en cours de chargement.",
        "loading_templates": "Vos modèles sont en cours de chargement.",
        "no_templates_available": "Aucun modèle n'est disponible.",
        "run_manually": "Vous pouvez exécuter ce modèle manuellement à l'aide de l'identifiant RID suivant : ${RID}",
        "template_duplicate_disabled": "Ce modèle ne peut pas être dupliqué.",
        "template_duplicate_error": "Impossible de dupliquer votre modèle",
        "template_duplicate_success_management": "Votre modèle a été dupliqué. Accédez à l'onglet \"Mes modèles\" pour l'utiliser et le modifier.",
        "template_edit_disabled": {
          "insufficient_permissions": "Vous n'êtes pas autorisé à modifier ce modèle.",
          "installed_template": {
            "default": "Les modèles fournis avec les solutions ne peuvent pas être modifiés. Dupliquez ce modèle pour en créer une version modifiable.",
            "duplicate_disabled": "Les modèles fournis avec les solutions ne peuvent pas être modifiés."
          }
        },
        "template_run_failed": "Nous n'avons pas pu créer votre {{resourceTypeLabel}}. Réessayez.",
        "template_run_success": "Création de {{resourceTypeLabel}}",
        "this_field_is_required": "Ce champ est obligatoire",
        "try_update_properties_again": "Réessayez de mettre à jour les propriétés de votre modèle.",
        "unable_to_create_template": "Nous n'avons pas pu créer votre modèle. Réessayez.",
        "unable_to_redirect": "Nous n'avons pas pu accéder à votre {{resourceTypeLabel}}",
        "updated_template_properties": "Propriétés du modèle mises à jour",
        "what_is_copied": "Ce qui sera copié",
        "what_is_not_copied": "Ce qui ne sera pas copié"
      },
      "permissions": {
        "doc_template": {
          "delete": "Peut supprimer",
          "modify": "Peut modifier",
          "presets": {
            "contributor": "Contributeur",
            "creator": "Créateur",
            "viewer": "Lecteur"
          },
          "share": "Peut partager",
          "source": "Pour ce modèle",
          "view": "Peut afficher"
        },
        "template": {
          "all_permissions": "Contrôle complet",
          "delete": "Peut supprimer le modèle",
          "edit": "Peut modifier le modèle",
          "read": "Peut utiliser le modèle",
          "share": "Peut gérer les accès au modèle"
        }
      },
      "prompts": {
        "choose_a_template": "Choisir un modèle",
        "create_template": "Créer un modèle",
        "delete_confirmation_multiple": "Êtes-vous sûr de vouloir supprimer {{count}} modèles ? Ces modèles seront définitivement supprimés et s'ils sont partagés, les utilisateurs n'y auront plus accès.",
        "delete_confirmation_single": "Êtes-vous sûr de vouloir supprimer le modèle {{name}} ? Ce modèle sera définitivement supprimé et s'il est partagé, les utilisateurs n'y auront plus accès.",
        "delete_template": "Supprimer le modèle",
        "duplicate": "Dupliquer",
        "edit_template": "Modifier le modèle",
        "manage_access": "Gérer les accès",
        "save_as_template": "Enregistrer en tant que modèle",
        "save_changes": "Enregistrer les modifications",
        "select_a_template": "Sélectionner un modèle",
        "use_template": "Utiliser le modèle",
        "use_a_template": "Utiliser un modèle",
        "view_details": "Afficher les détails"
      },
      "resource": {
        "type_template": "Modèle de type {{type}}",
        "sharefile_datatable": {
          "category_label": "Tableau",
          "label": "tableau",
          "preview": "Détails du tableau",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Enregistrez cette table de données en tant que modèle pouvant être utilisé pour créer d'autres tables."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Détails du document"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "workflow",
          "preview": "Détails du workflow",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Enregistrez ce workflow en tant que modèle pouvant être utilisé pour créer d'autres workflows."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Requêtes d'information",
          "label": "requête d'informations",
          "preview": "Détails de la requête d'informations",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Enregistrez cette demande d'information en tant que modèle réutilisable pour créer d'autres demandes."
          }
        },
        "sharefile_project": {
          "category_label": "Projets",
          "label": "projet",
          "preview": "Détails du projet",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Enregistrez ce projet en tant que modèle pouvant être utilisé pour créer d'autres projets."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Engagement",
          "label": "engagement",
          "preview": "Détails de l'engagement",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Enregistrez cet engagement comme modèle pouvant être utilisé pour créer d'autres engagements."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Dossier",
          "label": "Dossier",
          "preview": "Détails du dossier",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Enregistrez ce dossier comme modèle pouvant être utilisé pour créer d'autres dossiers."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Demandes de documents",
          "label": "demande de document",
          "preview": "Détails de la demande de document",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Enregistrez cette demande de document en tant que modèle réutilisable pour créer d'autres demandes de document."
          }
        }
      },
      "titles": {
        "dashboard": "Modèles"
      }
    }
  },
  {
    "key": "it",
    "content": {
      "common": {
        "actions": "Azioni",
        "all_categories": "Tutte le categorie",
        "back": "Indietro",
        "cancel": "Annulla",
        "change": "Modifica",
        "close": "Chiudi",
        "copy": "Copia",
        "create": "Crea",
        "created_by": "Creato da",
        "created_on": "Data di creazione",
        "delete": "Elimina",
        "description": "Descrizione",
        "details": "Dettagli",
        "duplicate": "Duplica",
        "duplicating": "Duplicazione",
        "edit": "Modifica",
        "more": "Altro",
        "my_templates": "Modelli personali",
        "name": "Nome",
        "optional": "facoltativo",
        "preview": "Anteprima",
        "save": "Salva",
        "search": "Cerca",
        "select": "Seleziona",
        "shared_with_me": "Condiviso con me",
        "solution_templates": "Modelli di soluzione",
        "template": "Modello",
        "template_name": "Nome del modello",
        "template_properties": "Proprietà del modello",
        "templates": "Modelli",
        "unknown": "Sconosciuto"
      },
      "messages": {
        "browse_catalog": "Sfoglia il catalogo",
        "browse_catalog_description": "Sfogliare il catalogo per trovare ulteriori modelli.",
        "couldnt_load_template": "Non è stato possibile caricare il modello.",
        "couldnt_load_templates": "Non è stato possibile caricare i modelli.",
        "couldnt_save_changes": "Non è stato possibile salvare le modifiche",
        "create_resource": "Crea {{resourceTypeLabel}}",
        "created_template": "Modello creato",
        "draft_state": "Il modello è stato creato, ma è attualmente in stato di bozza a causa di potenziali problemi di recupero delle risorse.",
        "delete_failure_multiple": "Non è stato caricare caricare i modelli",
        "delete_failure_single": "Non è stato possibile eliminare il modello {{name}}",
        "delete_success_multiple": "{{count}} modelli eliminati",
        "delete_success_single": "Modello eliminato",
        "editing_parameters": "Selezionare o inserire i valori dei parametri per iniziare. Questi valori verranno utilizzati solo come dati di esempio durante la personalizzazione del modello.",
        "editing_properties": "Modificare tutto ciò che è incluso in questo modello oppure creare qualcosa di nuovo.",
        "empty": {
          "error_loading_templates": {
            "title": "Non è stato possibile caricare i modelli"
          },
          "no_category_results": {
            "title": "Non si dispone di modelli {{resourceType}}"
          },
          "no_search_results": {
            "title": "La ricerca non trova corrispondenze in alcun modello",
            "subtitle": "Non siamo riusciti a trovare {{term}} nei titoli o nelle descrizioni dei modelli.",
            "action_label": "Cancella ricerca"
          },
          "my_templates": {
            "title": "Non hai ancora creato alcun modello applicabile",
            "subtitle": "I modelli che crei saranno disponibili qui."
          },
          "my_templates_ftu": {
            "title": "Benvenuto nella libreria di modelli",
            "subtitle": "Iniziare a costruire la libreria creando un modello.",
            "action_message": "Sono in arrivo altre opzioni di modelli"
          },
          "shared_templates": {
            "title": "Non disponi ancora di alcun modello condiviso applicabile",
            "subtitle": "I modelli creati da altri e condivisi con te saranno disponibili qui."
          },
          "shared_templates_ftu": {
            "title": "Modelli condivisi",
            "subtitle": "Qui si potranno trovare i modelli creati da altri utenti."
          },
          "installed_templates": {
            "title": "Non disponi ancora di alcun modello installato applicabile",
            "subtitle": "I modelli installati dal catalogo che possono essere utilizzati saranno disponibili qui.",
            "action_label": "Vai al catalogo"
          },
          "installed_templates_ftu": {
            "title": "Modelli installati",
            "subtitle": "I modelli installati dal catalogo saranno disponibili qui.",
            "action_label": "Vai al catalogo"
          }
        },
        "generic_error": "Si è verificato un errore.",
        "hint_text_template_name": "Questo nome verrà visualizzato nella galleria dei modelli.",
        "loading_template": "I dettagli del modello sono in fase di caricamento.",
        "loading_templates": "I modelli sono in fase di caricamento.",
        "no_templates_available": "Non ci sono modelli disponibili.",
        "run_manually": "È possibile eseguire questo modello manualmente utilizzando il seguente RID: ${RID}",
        "template_duplicate_disabled": "Questo modello non può essere duplicato.",
        "template_duplicate_error": "Non è stato possibile duplicare il modello",
        "template_duplicate_success_management": "Il modello è stato duplicato. Andare alla scheda \"I miei modelli\" per utilizzarlo e modificarlo.",
        "template_edit_disabled": {
          "insufficient_permissions": "Non si dispone dell'autorizzazione per modificare questo modello.",
          "installed_template": {
            "default": "I modelli forniti con le soluzioni non possono essere modificati. Duplicare questo modello per creare una versione modificabile.",
            "duplicate_disabled": "I modelli forniti con le soluzioni non possono essere modificati."
          }
        },
        "template_run_failed": "Non siamo riusciti a creare il tuo {{resourceTypeLabel}}. Si prega di riprovare.",
        "template_run_success": "Creazione di {{resourceTypeLabel}} completata",
        "this_field_is_required": "Questo campo è obbligatorio",
        "try_update_properties_again": "Riprova ad aggiornare le proprietà del modello.",
        "unable_to_create_template": "Non è stato possibile creare il modello. Riprovare.",
        "unable_to_redirect": "Non è stato possibile passare a {{resourceTypeLabel}}",
        "updated_template_properties": "Proprietà del modello aggiornate",
        "what_is_copied": "Cosa verrà copiato",
        "what_is_not_copied": "Cosa non verrà copiato"
      },
      "permissions": {
        "doc_template": {
          "delete": "Può eliminare",
          "modify": "Può modificare",
          "presets": {
            "contributor": "Collaboratore",
            "creator": "Autore",
            "viewer": "Visualizzatore"
          },
          "share": "Può condividere",
          "source": "Per questo modello",
          "view": "Può visualizzare"
        },
        "template": {
          "all_permissions": "Pieno controllo",
          "delete": "Può eliminare il modello",
          "edit": "Può modificare il modello",
          "read": "Può utilizzare il modello",
          "share": "Può gestire l'accesso al modello"
        }
      },
      "prompts": {
        "choose_a_template": "Scegli modello",
        "create_template": "Crea modello",
        "delete_confirmation_multiple": "Eliminare i modelli {{count}}? Questi modelli saranno eliminati definitivamente. Se questi modelli sono condivisi, gli utenti perderanno l'accesso.",
        "delete_confirmation_single": "Eliminare il modello {{name}}? Il modello verrà eliminato definitivamente. Se questo modello è condiviso, gli utenti perderanno l'accesso.",
        "delete_template": "Elimina modello",
        "duplicate": "Duplica",
        "edit_template": "Modifica modello",
        "manage_access": "Gestisci l'accesso",
        "save_as_template": "Salva come modello",
        "save_changes": "Salva modifiche",
        "select_a_template": "Seleziona un modello",
        "use_template": "Usa modello",
        "use_a_template": "Utilizza un modello",
        "view_details": "Visualizza dettagli"
      },
      "resource": {
        "type_template": "Modello {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabella",
          "label": "tabella",
          "preview": "Dettagli tabella",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Salvare questa tabella di dati come modello che può essere utilizzato per creare nuovi flussi di lavoro."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Dettagli documento"
        },
        "sharefile_flow": {
          "category_label": "Flussi di lavoro",
          "label": "flusso di lavoro",
          "preview": "Dettagli del flusso di lavoro",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Salvare questo flusso di lavoro come modello che può essere utilizzato per creare nuovi flussi di lavoro."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Richieste di informazioni",
          "label": "richiesta di informazioni",
          "preview": "Dettagli della richiesta di informazioni",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Salvare questa richiesta di informazioni come modello che può essere utilizzato per creare nuove richieste."
          }
        },
        "sharefile_project": {
          "category_label": "Progetti",
          "label": "progetto",
          "preview": "Dettagli del progetto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvare il progetto come modello che può essere utilizzato per creare nuovi progetti."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Incarico",
          "label": "incarico",
          "preview": "Dettagli dell'incarico",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvare questo incarico come modello che può essere utilizzato per creare nuovi incarichi."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Pratica",
          "label": "Pratica",
          "preview": "Dettagli della pratica",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvare questa pratica come modello che può essere utilizzato per creare nuove pratiche."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Richieste di documenti",
          "label": "richiesta di documenti",
          "preview": "Dettagli della richiesta di documenti",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Salvare questa richiesta di documento come modello che può essere utilizzato per creare nuove richieste di documenti."
          }
        }
      },
      "titles": {
        "dashboard": "Modelli"
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "common": {
        "actions": "操作",
        "all_categories": "すべてのカテゴリ",
        "back": "戻る",
        "cancel": "キャンセル",
        "change": "変更",
        "close": "閉じる",
        "copy": "コピー",
        "create": "作成",
        "created_by": "作成者",
        "created_on": "作成日:",
        "delete": "削除",
        "description": "説明",
        "details": "詳細",
        "duplicate": "複製",
        "duplicating": "複製しています",
        "edit": "編集",
        "more": "詳細",
        "my_templates": "自分のテンプレート",
        "name": "名前",
        "optional": "オプション",
        "preview": "プレビュー",
        "save": "保存",
        "search": "検索",
        "select": "選択",
        "shared_with_me": "ユーザーと共有",
        "solution_templates": "ソリューション テンプレート",
        "template": "テンプレート",
        "template_name": "テンプレート名",
        "template_properties": "テンプレートのプロパティ",
        "templates": "テンプレート",
        "unknown": "不明"
      },
      "messages": {
        "browse_catalog": "カタログを参照する",
        "browse_catalog_description": "その他のテンプレートについてはカタログを参照してください。",
        "couldnt_load_template": "テンプレートを読み込めませんでした。",
        "couldnt_load_templates": "テンプレートを読み込めませんでした。",
        "couldnt_save_changes": "変更を保存できませんでした",
        "create_resource": "{{resourceTypeLabel}} を作成する",
        "created_template": "作成済みのテンプレート",
        "draft_state": "テンプレートは作成されましたが、リソースの取得に問題が発生する可能性があるため、現在は下書き状態です。",
        "delete_failure_multiple": "テンプレートを削除できませんでした",
        "delete_failure_single": "{{name}} のテンプレートを削除できませんでした",
        "delete_success_multiple": "{{count}} 個のテンプレートを削除しました",
        "delete_success_single": "テンプレートを削除しました",
        "editing_parameters": "開始するには、任意のパラメーター値を選択または入力します。これらの値は、テンプレートをカスタマイズするときにサンプル データとしてのみ使用されます。",
        "editing_properties": "このテンプレートに付属する要素を編集するか、新しい要素を作成します。",
        "empty": {
          "error_loading_templates": {
            "title": "テンプレートを読み込めませんでした"
          },
          "no_category_results": {
            "title": "{{resourceType}} テンプレートがありません"
          },
          "no_search_results": {
            "title": "検索条件に一致するテンプレートはありません",
            "subtitle": "テンプレートのタイトルまたは説明に {{term}} が見つかりませんでした。",
            "action_label": "検索をクリア"
          },
          "my_templates": {
            "title": "適用可能なテンプレートはまだ作成されていません",
            "subtitle": "作成したテンプレートはここに保存されます。"
          },
          "my_templates_ftu": {
            "title": "テンプレート ライブラリへようこそ",
            "subtitle": "テンプレートを作成してライブラリの構築を開始しましょう。",
            "action_message": "テンプレート オプションの追加を予定しています"
          },
          "shared_templates": {
            "title": "適用できる共有テンプレートはまだありません",
            "subtitle": "他のユーザーが作成し、あなたと共有したテンプレートはここに保存されます。"
          },
          "shared_templates_ftu": {
            "title": "共有されたテンプレート",
            "subtitle": "他のユーザーが作成したテンプレートをここで見つけることができます。"
          },
          "installed_templates": {
            "title": "適用できるインストール済みテンプレートはまだありません",
            "subtitle": "カタログからインストールされた使用可能なテンプレートはここに保存されます。",
            "action_label": "カタログに移動する"
          },
          "installed_templates_ftu": {
            "title": "インストール済みテンプレート",
            "subtitle": "カタログからインストールしたテンプレートはここに保存されます。",
            "action_label": "カタログに移動する"
          }
        },
        "generic_error": "エラーが発生しました。",
        "hint_text_template_name": "この名前はテンプレート ギャラリーに表示されます。",
        "loading_template": "テンプレートの詳細を読み込み中です。",
        "loading_templates": "テンプレートを読み込み中です。",
        "no_templates_available": "利用可能なテンプレートはありません。",
        "run_manually": "次の RID を使用して、このテンプレートを手動で実行できます: ${RID}",
        "template_duplicate_disabled": "このテンプレートは複製できません。",
        "template_duplicate_error": "テンプレートは重複して作成できません",
        "template_duplicate_success_management": "テンプレートが複製されました。[マイ テンプレート] タブから使用および編集できます。",
        "template_edit_disabled": {
          "insufficient_permissions": "このテンプレートを編集する権限がありません。",
          "installed_template": {
            "default": "ソリューション付きのテンプレートは編集できません。編集可能なバージョンを作成するには、このテンプレートを複製してください。",
            "duplicate_disabled": "ソリューション付きのテンプレートは編集できません。"
          }
        },
        "template_run_failed": "{{resourceTypeLabel}} を作成できませんでした。再試行してください。",
        "template_run_success": "作成された {{resourceTypeLabel}}",
        "this_field_is_required": "このフィールドは必須です",
        "try_update_properties_again": "テンプレートのプロパティを再度更新してください。",
        "unable_to_create_template": "テンプレートを作成できませんでした。再試行してください。",
        "unable_to_redirect": "{{resourceTypeLabel}} に移動できませんでした",
        "updated_template_properties": "更新されたテンプレートのプロパティ",
        "what_is_copied": "コピーされる内容",
        "what_is_not_copied": "コピーされない内容"
      },
      "permissions": {
        "doc_template": {
          "delete": "削除できる",
          "modify": "変更できる",
          "presets": {
            "contributor": "貢献者",
            "creator": "作成者",
            "viewer": "閲覧者"
          },
          "share": "共有できる",
          "source": "このテンプレートの場合",
          "view": "表示できる"
        },
        "template": {
          "all_permissions": "フル コントロール",
          "delete": "テンプレートを削除できる",
          "edit": "テンプレートを編集できる",
          "read": "テンプレートを使用できる",
          "share": "テンプレートへのアクセスを管理できる"
        }
      },
      "prompts": {
        "choose_a_template": "テンプレートの選択",
        "create_template": "テンプレートを作成する",
        "delete_confirmation_multiple": "{{count}} 個のテンプレートを削除してもよろしいですか? これらのテンプレートは完全に削除されるため、共有されている場合、ユーザーはアクセスできなくなります。",
        "delete_confirmation_single": "{{name}} テンプレートを削除してもよろしいですか? このテンプレートは完全に削除されるため、共有されている場合、ユーザーはアクセスできなくなります。",
        "delete_template": "テンプレートを削除する",
        "duplicate": "複製",
        "edit_template": "テンプレートの編集",
        "manage_access": "アクセスを管理する",
        "save_as_template": "テンプレートとして保存する",
        "save_changes": "変更の保存",
        "select_a_template": "テンプレートを選択する",
        "use_template": "テンプレートを使用する",
        "use_a_template": "テンプレートを使用",
        "view_details": "詳細を表示"
      },
      "resource": {
        "type_template": "{{type}} テンプレート",
        "sharefile_datatable": {
          "category_label": "表",
          "label": "表",
          "preview": "表の詳細",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "このデータ テーブルを、新しいテーブルの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_document": {
          "category_label": "ドキュメント",
          "label": "ドキュメント",
          "preview": "ドキュメントの詳細"
        },
        "sharefile_flow": {
          "category_label": "ワークフロー",
          "label": "ワークフロー",
          "preview": "ワークフローの詳細",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "このワークフローを、新しいワークフローの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_inforeq": {
          "category_label": "情報リクエスト",
          "label": "情報リクエスト",
          "preview": "情報リクエストの詳細",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "この情報リクエストを、新しいリクエストの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_project": {
          "category_label": "プロジェクト",
          "label": "プロジェクト",
          "preview": "プロジェクトの詳細",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "このプロジェクトを、新しいプロジェクトの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_project_accounting": {
          "category_label": "エンゲージメント",
          "label": "エンゲージメント",
          "preview": "エンゲージメントの詳細",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "このエンゲージメントを、新しいエンゲージメントの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_project_legal": {
          "category_label": "案件",
          "label": "案件",
          "preview": "案件の詳細",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "この案件を、新しい案件の作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_requestlist": {
          "category_label": "ドキュメント リクエスト",
          "label": "ドキュメント リクエスト",
          "preview": "ドキュメント リクエストの詳細",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "このドキュメント リクエストを、新しいドキュメント リクエストの作成に使用できるテンプレートとして保存します。"
          }
        }
      },
      "titles": {
        "dashboard": "テンプレート"
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "common": {
        "actions": "Acties",
        "all_categories": "Alle categorieën",
        "back": "Terug",
        "cancel": "Annuleren",
        "change": "Wijzigen",
        "close": "Sluiten",
        "copy": "Kopiëren",
        "create": "Maken",
        "created_by": "Gemaakt door",
        "created_on": "Gemaakt op",
        "delete": "Verwijderen",
        "description": "Beschrijving",
        "details": "Details",
        "duplicate": "Dupliceren",
        "duplicating": "Dupliceren",
        "edit": "Bewerken",
        "more": "Meer",
        "my_templates": "Mijn sjablonen",
        "name": "Naam",
        "optional": "optioneel",
        "preview": "Voorbeeldweergave",
        "save": "Opslaan",
        "search": "Zoeken",
        "select": "Selecteren",
        "shared_with_me": "Gedeeld met mij",
        "solution_templates": "Sjablonen voor oplossingen",
        "template": "Sjabloon",
        "template_name": "Sjabloonnaam",
        "template_properties": "Sjablooneigenschappen",
        "templates": "Sjablonen",
        "unknown": "Onbekend"
      },
      "messages": {
        "browse_catalog": "Door catalogus bladeren",
        "browse_catalog_description": "Blader door de catalogus voor meer sjablonen.",
        "couldnt_load_template": "We kunnen uw sjabloon niet laden.",
        "couldnt_load_templates": "We kunnen uw sjablonen niet laden.",
        "couldnt_save_changes": "Uw wijzigingen konden niet worden opgeslagen",
        "create_resource": "{{resourceTypeLabel}} maken",
        "created_template": "Sjabloon gemaakt",
        "draft_state": "Uw sjabloon is gemaakt, maar bevindt zich momenteel in een conceptstatus vanwege mogelijke problemen met het ophalen van bronnen.",
        "delete_failure_multiple": "We kunnen uw sjablonen niet verwijderen",
        "delete_failure_single": "We konden de sjabloon {{name}} niet verwijderen",
        "delete_success_multiple": "{{count}} sjablonen verwijderd",
        "delete_success_single": "Sjabloon verwijderd",
        "editing_parameters": "Selecteer of voer parameterwaarden in om aan de slag te gaan. Deze waarden worden alleen gebruikt als voorbeeldgegevens wanneer u uw sjabloon aanpast.",
        "editing_properties": "Bewerk alles wat bij dit sjabloon wordt geleverd, of maak iets nieuws.",
        "empty": {
          "error_loading_templates": {
            "title": "We kunnen uw sjablonen niet laden"
          },
          "no_category_results": {
            "title": "U hebt geen {{resourceType}}-sjablonen"
          },
          "no_search_results": {
            "title": "Uw zoekopdracht komt met geen enkele sjabloon overeen",
            "subtitle": "We hebben {{term}} niet gevonden in sjabloontitels of -beschrijvingen.",
            "action_label": "Zoekopdracht wissen"
          },
          "my_templates": {
            "title": "U hebt nog geen toepasbare sjablonen gemaakt",
            "subtitle": "De sjablonen die u maakt, worden hier bewaard."
          },
          "my_templates_ftu": {
            "title": "Welkom bij uw sjablonenbibliotheek",
            "subtitle": "Begin uw bibliotheek op te bouwen door een sjabloon te maken.",
            "action_message": "Binnenkort meer sjabloonopties beschikbaar"
          },
          "shared_templates": {
            "title": "U hebt nog geen toepasbare gedeelde sjablonen",
            "subtitle": "Sjablonen die door anderen zijn gemaakt en met u zijn gedeeld, worden hier bewaard."
          },
          "shared_templates_ftu": {
            "title": "Gedeelde sjablonen",
            "subtitle": "Hier kunt u sjablonen vinden die andere gebruikers hebben gemaakt."
          },
          "installed_templates": {
            "title": "U hebt nog geen toepasbare sjablonen geïnstalleerd",
            "subtitle": "Sjablonen die uit de catalogus zijn geïnstalleerd en die kunnen worden gebruikt, worden hier bewaard.",
            "action_label": "Ga naar de catalogus"
          },
          "installed_templates_ftu": {
            "title": "Geïnstalleerde sjablonen",
            "subtitle": "Sjablonen die u vanuit de catalogus installeert, worden hier bewaard.",
            "action_label": "Ga naar de catalogus"
          }
        },
        "generic_error": "Er is een fout opgetreden.",
        "hint_text_template_name": "Deze naam wordt weergegeven in de sjabloongalerij.",
        "loading_template": "Sjabloongegevens worden geladen.",
        "loading_templates": "Uw sjablonen worden geladen.",
        "no_templates_available": "Er zijn geen sjablonen beschikbaar.",
        "run_manually": "U kunt dit sjabloon handmatig uitvoeren met behulp van de volgende RID: ${RID}",
        "template_duplicate_disabled": "Dit sjabloon kan niet worden gedupliceerd.",
        "template_duplicate_error": "We konden uw sjabloon niet dupliceren",
        "template_duplicate_success_management": "Uw sjabloon is gedupliceerd. Navigeer naar het tabblad 'Mijn sjablonen' om het te gebruiken en te bewerken.",
        "template_edit_disabled": {
          "insufficient_permissions": "U bent niet gemachtigd om dit sjabloon te bewerken.",
          "installed_template": {
            "default": "Sjablonen die met oplossingen worden geleverd, kunnen niet worden bewerkt. Dupliceer dit sjabloon om een versie te maken die u kunt bewerken.",
            "duplicate_disabled": "Sjablonen die met oplossingen worden geleverd, kunnen niet worden bewerkt."
          }
        },
        "template_run_failed": "Wij kunnen uw {{resourceTypeLabel}} niet maken. Probeer het opnieuw.",
        "template_run_success": "{{resourceTypeLabel}} gemaakt",
        "this_field_is_required": "Dit veld is verplicht",
        "try_update_properties_again": "Probeer de eigenschappen van uw sjabloon opnieuw bij te werken.",
        "unable_to_create_template": "Wij kunnen uw sjabloon niet maken. Probeer het opnieuw.",
        "unable_to_redirect": "We kunnen niet naar uw {{resourceTypeLabel}} gaan",
        "updated_template_properties": "Bijgewerkte sjablooneigenschappen",
        "what_is_copied": "Wat er wordt gekopieerd",
        "what_is_not_copied": "Wat er niet wordt gekopieerd"
      },
      "permissions": {
        "doc_template": {
          "delete": "Kan verwijderen",
          "modify": "Kan wijzigen",
          "presets": {
            "contributor": "Bijdrager",
            "creator": "Auteur",
            "viewer": "Waarnemer"
          },
          "share": "Kan delen",
          "source": "Voor dit sjabloon",
          "view": "Kan bekijken"
        },
        "template": {
          "all_permissions": "Volledige controle",
          "delete": "Kan het documentsjabloon verwijderen",
          "edit": "Kan het sjabloon bewerken",
          "read": "Kan het sjabloon gebruiken",
          "share": "Kan de toegang tot het sjabloon beheren"
        }
      },
      "prompts": {
        "choose_a_template": "Een sjabloon kiezen",
        "create_template": "Sjabloon maken",
        "delete_confirmation_multiple": "Weet u zeker dat u {{count}} sjablonen wilt verwijderen? Deze sjablonen worden permanent verwijderd. Als deze sjablonen worden gedeeld, verliezen gebruikers de toegang.",
        "delete_confirmation_single": "Weet u zeker dat u het sjabloon {{name}} wilt verwijderen? Het sjabloon wordt permanent verwijderd. Als dit sjabloon wordt gedeeld, verliezen gebruikers de toegang.",
        "delete_template": "Sjabloon verwijderen",
        "duplicate": "Duplicaat",
        "edit_template": "Sjabloon bewerken",
        "manage_access": "Toegang beheren",
        "save_as_template": "Opslaan als sjabloon",
        "save_changes": "Wijzigingen opslaan",
        "select_a_template": "Selecteer een sjabloon",
        "use_template": "Sjabloon gebruiken",
        "use_a_template": "Een sjabloon gebruiken",
        "view_details": "Details weergeven"
      },
      "resource": {
        "type_template": "{{type}}-sjabloon",
        "sharefile_datatable": {
          "category_label": "Tabel",
          "label": "tabel",
          "preview": "Tabelgegevens",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Sla deze gegegvenstabel op als een sjabloon die kan worden gebruikt om nieuwe tabellen te maken."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Documentgegevens"
        },
        "sharefile_flow": {
          "category_label": "Werkstromen",
          "label": "werkstroom",
          "preview": "Details werkstroom",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Sla dit project op als een sjabloon die kan worden gebruikt om nieuwe werkstromen te maken."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Informatieaanvragen",
          "label": "informatieaanvraag",
          "preview": "Gegevens van informatieaanvraag",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Sla deze informatieaanvraag op als een sjabloon die kan worden gebruikt om nieuwe aanvragen te maken."
          }
        },
        "sharefile_project": {
          "category_label": "Projecten",
          "label": "project",
          "preview": "Details van project",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Sla dit project op als een sjabloon die kan worden gebruikt om nieuwe projecten te maken."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Opdracht",
          "label": "opdracht",
          "preview": "Details van de opdracht",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Sla deze opdracht op als een sjabloon die kan worden gebruikt om nieuwe opdrachten te maken."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Zaak",
          "label": "Zaak",
          "preview": "Details van de zaak",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Sla deze zaak op als een sjabloon die kan worden gebruikt om nieuwe zaken te maken."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Documentaanvragen",
          "label": "documentaanvraag",
          "preview": "Details van documentaanvraag",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Sla deze documentaanvraag op als een sjabloon die kan worden gebruikt om nieuwe documentaanvragen te maken."
          }
        }
      },
      "titles": {
        "dashboard": "Sjablonen"
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "common": {
        "actions": "Ações",
        "all_categories": "Todas as categorias",
        "back": "Voltar",
        "cancel": "Cancelar",
        "change": "Alterar",
        "close": "Fechar",
        "copy": "Copiar",
        "create": "Criar",
        "created_by": "Criado por",
        "created_on": "Criado em",
        "delete": "Excluir",
        "description": "Descrição",
        "details": "Detalhes",
        "duplicate": "Duplicado",
        "duplicating": "Duplicando",
        "edit": "Editar",
        "more": "Mais",
        "my_templates": "Meus modelos",
        "name": "Nome",
        "optional": "opcional",
        "preview": "Visualizar",
        "save": "Salvar",
        "search": "Pesquisar",
        "select": "Selecionar",
        "shared_with_me": "Compartilhado comigo",
        "solution_templates": "Modelos de solução",
        "template": "Modelo",
        "template_name": "Nome do modelo",
        "template_properties": "Propriedades do modelo",
        "templates": "Modelos",
        "unknown": "Desconhecido"
      },
      "messages": {
        "browse_catalog": "Navegar no catálogo",
        "browse_catalog_description": "Navegue pelo catálogo para ver mais modelos.",
        "couldnt_load_template": "Não foi possível carregar o seu modelo.",
        "couldnt_load_templates": "Não foi possível carregar os seus modelos.",
        "couldnt_save_changes": "Não foi possível salvar suas alterações",
        "create_resource": "Criar {{resourceTypeLabel}}",
        "created_template": "Modelo criado",
        "draft_state": "Seu modelo foi criado, mas atualmente está em estado de rascunho devido a possíveis problemas com a busca de recursos.",
        "delete_failure_multiple": "Não foi possível excluir seus modelos",
        "delete_failure_single": "Não foi possível excluir o modelo {{name}}",
        "delete_success_multiple": "{{count}} modelos excluídos",
        "delete_success_single": "Modelo excluído",
        "editing_parameters": "Selecione ou insira qualquer valor de parâmetro para começar. Esses valores serão usados apenas como dados de amostra conforme você personaliza seu modelo.",
        "editing_properties": "Edite qualquer parte desse modelo ou crie algo novo.",
        "empty": {
          "error_loading_templates": {
            "title": "Não foi possível carregar seus modelos"
          },
          "no_category_results": {
            "title": "Você não tem nenhum modelo de {{resourceType}}"
          },
          "no_search_results": {
            "title": "Sua pesquisa não corresponde a nenhum modelo",
            "subtitle": "Não foi possível encontrar {{term}} em nenhum título ou descrição de modelo.",
            "action_label": "Limpar pesquisa"
          },
          "my_templates": {
            "title": "Você ainda não criou nenhum modelo aplicável",
            "subtitle": "Os modelos que você criar ficarão aqui."
          },
          "my_templates_ftu": {
            "title": "Bem-vindo à sua biblioteca de modelos",
            "subtitle": "Comece a criar sua biblioteca criando um modelo.",
            "action_message": "Mais opções de modelos em breve"
          },
          "shared_templates": {
            "title": "Você ainda não tem modelos compartilhados aplicáveis",
            "subtitle": "Os modelos criados por outras pessoas e compartilhados com você ficarão aqui."
          },
          "shared_templates_ftu": {
            "title": "Modelos compartilhados",
            "subtitle": "Aqui você poderá encontrar modelos que outros usuários criaram."
          },
          "installed_templates": {
            "title": "Você ainda não tem modelos instalados aplicáveis",
            "subtitle": "Os modelos instalados do catálogo que podem ser usados ficarão aqui.",
            "action_label": "Ir para o catálogo"
          },
          "installed_templates_ftu": {
            "title": "Modelos instalados",
            "subtitle": "Os modelos que você instalar do catálogo ficarão aqui.",
            "action_label": "Ir para o catálogo"
          }
        },
        "generic_error": "Ocorreu um erro.",
        "hint_text_template_name": "Este nome aparecerá na galeria de modelos.",
        "loading_template": "Os detalhes do modelo estão sendo carregados.",
        "loading_templates": "Seus modelos estão sendo carregados.",
        "no_templates_available": "Não há modelos disponíveis.",
        "run_manually": "Você pode executar este modelo manualmente usando o seguinte RID: ${RID}",
        "template_duplicate_disabled": "Este modelo não pode ser duplicado.",
        "template_duplicate_error": "Não foi possível duplicar seu modelo",
        "template_duplicate_success_management": "Seu modelo foi duplicado. Navegue até a guia \"Meus modelos\" para usá-lo e editá-lo.",
        "template_edit_disabled": {
          "insufficient_permissions": "Você não tem permissão para editar esse modelo.",
          "installed_template": {
            "default": "Os modelos que acompanham as soluções não podem ser editados. Duplique esse modelo para criar uma versão que você pode editar.",
            "duplicate_disabled": "Os modelos que acompanham as soluções não podem ser editados."
          }
        },
        "template_run_failed": "Não foi possível criar o seu {{resourceTypeLabel}}. Tente novamente.",
        "template_run_success": "{{resourceTypeLabel}} criado",
        "this_field_is_required": "Este campo é obrigatório",
        "try_update_properties_again": "Tente atualizar as propriedades do seu modelo novamente.",
        "unable_to_create_template": "Não foi possível criar o seu modelo. Tente novamente.",
        "unable_to_redirect": "Não foi possível navegar para seu {{resourceTypeLabel}}",
        "updated_template_properties": "Propriedades do modelo atualizadas",
        "what_is_copied": "O que será copiado",
        "what_is_not_copied": "O que não será copiado"
      },
      "permissions": {
        "doc_template": {
          "delete": "Pode excluir",
          "modify": "Pode modificar",
          "presets": {
            "contributor": "Colaborador",
            "creator": "Criador",
            "viewer": "Observador"
          },
          "share": "Pode compartilhar",
          "source": "Para este modelo",
          "view": "Pode exibir"
        },
        "template": {
          "all_permissions": "Controle total",
          "delete": "Pode excluir o modelo",
          "edit": "Pode editar o modelo",
          "read": "Pode usar o modelo",
          "share": "Pode gerenciar o acesso ao modelo"
        }
      },
      "prompts": {
        "choose_a_template": "Escolher um modelo",
        "create_template": "Criar modelo",
        "delete_confirmation_multiple": "Tem certeza de que deseja excluir {{count}} modelos? Esses modelos serão excluídos permanentemente. Se esses modelos forem compartilhados, os usuários perderão o acesso.",
        "delete_confirmation_single": "Tem certeza de que deseja excluir o modelo {{name}}? O modelo será excluído permanentemente. Se esse modelo for compartilhado, os usuários perderão o acesso.",
        "delete_template": "Excluir modelo",
        "duplicate": "Duplicar",
        "edit_template": "Editar modelo",
        "manage_access": "Gerenciar acesso",
        "save_as_template": "Salvar como modelo",
        "save_changes": "Salvar alterações",
        "select_a_template": "Selecione um modelo",
        "use_template": "Usar modelo",
        "use_a_template": "Usar um modelo",
        "view_details": "Exibir detalhes"
      },
      "resource": {
        "type_template": "Modelo de {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabela",
          "label": "tabela",
          "preview": "Detalhes da tabela",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Salve esta tabela de dados como um modelo que pode ser usado para criar novas tabelas."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Detalhes do documento"
        },
        "sharefile_flow": {
          "category_label": "Fluxos de trabalho",
          "label": "fluxo de trabalho",
          "preview": "Detalhes de fluxo de trabalho",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Salvar este fluxo de trabalho como um modelo que pode ser usado para criar novos fluxos de trabalho."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Solicitações de informações",
          "label": "solicitação de informações",
          "preview": "Detalhes da solicitação de informações",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Salve esta solicitação de informações como um modelo que pode ser usado para criar novas solicitações."
          }
        },
        "sharefile_project": {
          "category_label": "Projetos",
          "label": "projeto",
          "preview": "Detalhes do projeto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvar este projeto como um modelo que pode ser usado para criar novos projetos."
          }
        },
        "sharefile_project_accounting": {
          "category_label": "Compromisso",
          "label": "compromisso",
          "preview": "Detalhes do compromisso",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the engagement",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salve este compromisso como um modelo que pode ser usado para criar novos compromissos."
          }
        },
        "sharefile_project_legal": {
          "category_label": "Caso",
          "label": "Caso",
          "preview": "Detalhes do caso",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the matter",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salve este caso como um modelo que pode ser usado para criar novos casos."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Solicitações de documentos",
          "label": "solicitação de documento",
          "preview": "Detalhes da solicitação de documento",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Salve esta solicitação de documento como um modelo que pode ser usado para criar novas solicitações de documentos."
          }
        }
      },
      "titles": {
        "dashboard": "Modelos"
      }
    }
  }
];